<template>
  <AtroContent class="w-full" direction="col" items="center">
    <AtroContent
      class="-ml-4 mb-6 w-[calc(100%_+_2em)] bg-atro-warm-gray-2 py-10 sm:mb-12 sm:ml-0 sm:w-full sm:rounded-3xl sm:p-10"
      direction="col"
      items="center"
      wrap="nowrap"
    >
      <AtroHeading
        semibold
        class="mb-8 text-atro-slate-purple"
        size="lg"
        text="Security level"
      />

      <!-- SECURITY LEVEL BAR -->
      <RiskBar :risk-level="modelValue.riskLevel" />

      <AtroButton
        v-if="onboarding"
        class="mt-8"
        text="Lower my risk"
        @click="onClaimAccount"
      />

      <!-- ALERTS OVERVIEW -->
      <div class="w-full px-4 sm:w-auto">
        <AtroContent
          class="mt-10 w-full rounded-3xl bg-white py-6 sm:px-16"
          direction="col"
          items="center"
          wrap="nowrap"
        >
          <AtroHeading
            semibold
            class="text-atro-slate-purple"
            size="sm"
            text="Alerts Overview"
          />
          <AtroContent
            class="mt-4"
            direction="col"
            items="center"
            wrap="nowrap"
          >
            <Motion
              :class="[
                'flex flex-col flex-nowrap items-center rounded-xl px-8 py-2.5 text-white',
                {
                  'bg-atro-red': totalAlertCount > 0,
                  'bg-atro-green': totalAlertCount === 0,
                },
              ]"
              :initial="{ opacity: 0, scale: 0.25 }"
              :animate="{ opacity: 1, scale: 1 }"
              :transition="{
                delay: 0.25,
                duration: 1,
                easing: spring({
                  damping: 8,
                }),
              }"
            >
              <AtroHeading semibold size="xl">
                <AtroCounter :to="totalAlertCount" :delay="0.3" :duration="1" />
              </AtroHeading>
              <AtroSpan semibold class="-mt-2.5" size="xs" text="Total" />
            </Motion>
            <AtroContent
              class="mt-4 space-y-2 sm:-mt-4"
              direction="col"
              items="center"
              wrap="nowrap"
            >
              <Motion
                class="flex justify-between space-x-2 sm:space-x-52"
                :initial="{ opacity: 0, scale: 0.25 }"
                :animate="{ opacity: 1, scale: 1 }"
                :transition="{
                  delay: 0.5,
                  duration: 1,
                }"
              >
                <CategoryAlertBadge
                  category="devices"
                  :count="alertCounts?.devices"
                />
                <CategoryAlertBadge
                  category="cloud"
                  :count="alertCounts?.cloud"
                />
              </Motion>

              <Motion
                class="flex justify-between space-x-2 sm:space-x-20"
                :initial="{ opacity: 0, scale: 0.25 }"
                :animate="{ opacity: 1, scale: 1 }"
                :transition="{
                  delay: 0.75,
                  duration: 1,
                }"
              >
                <CategoryAlertBadge
                  category="people"
                  :count="alertCounts?.people"
                />
                <CategoryAlertBadge
                  category="networks"
                  :count="alertCounts?.networks"
                />
              </Motion>

              <Motion
                :initial="{ opacity: 0, scale: 0.25 }"
                :animate="{ opacity: 1, scale: 1 }"
                :transition="{
                  delay: 1,
                  duration: 1,
                }"
              >
                <CategoryAlertBadge
                  category="software"
                  :count="alertCounts?.software"
                />
              </Motion>
            </AtroContent>
          </AtroContent>
        </AtroContent>
      </div>

      <!-- TOP ALERTS -->
      <AtroContent class="mt-10 w-full" direction="col">
        <AtroContent class="w-full text-atro-red" justify="center">
          <AtroIcon class="mr-2 h-6 w-6" name="circle-exclamation" />
          <AtroHeading semibold size="xs" :text="recommendedFlowCardHeading" />
        </AtroContent>

        <AtroContent
          class="mt-6 grid w-full justify-items-center gap-4 sm:grid-cols-3"
        >
          <AtroContent
            v-for="recommendedFlow in modelValue.recommendedFlows"
            :key="recommendedFlow.id"
            class="sm:w-[var(--flow-card-preview-width)]"
            direction="col"
            items="center"
          >
            <AtroContent
              class="mb-2 w-full px-4 sm:px-0"
              direction="col"
              items="center"
            >
              <AtroContent class="w-full rounded-2xl bg-atro-red-15 p-4">
                <AtroSpan
                  centered
                  semibold
                  class="line-clamp-2 text-atro-red"
                  size="sm"
                  :text="recommendedFlow.ctaDescription"
                />
              </AtroContent>
              <AtroIcon class="h-5 w-5 text-atro-red" name="arrow-down" />
            </AtroContent>

            <FlowCard :flow="recommendedFlow" view="preview" />
          </AtroContent>
        </AtroContent>
      </AtroContent>

      <!-- REVEAL CTA CARD -->
      <AtroContent
        v-if="onboarding"
        class="mt-10 w-full rounded-3xl bg-white p-6"
        direction="col"
        items="center"
      >
        <AtroIconWrap
          circle
          size="lg"
          :type="totalAlertCount > 0 ? 'warning' : 'dark'"
          :name="signupCardIcon"
        />
        <AtroSpan
          v-if="totalAlertCount > 0"
          semibold
          class="mt-2 text-atro-red"
          size="lg"
          >+ {{ totalAlertCount }} more alerts</AtroSpan
        >
        <AtroParagraph
          semibold
          class="mt-6 text-atro-dark-purple"
          size="lg"
          :text="signupCardText"
        />
        <AtroButton class="mt-4" text="Sign up free" @click="onClaimAccount" />
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import { spring } from '@oku-ui/motion'

type Model = {
  alertCounts: {
    cloud: number
    devices: number
    networks: number
    people: number
    software: number
  }
  recommendedFlows: Flow[]
  riskLevel: number
}

export interface Props {
  modelValue: Model

  onboarding?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
  'update:modelValue': [model: Props['modelValue']]
}>()
const modelValue = useVModel(props, 'modelValue', emit)

const alertCounts = computed(() => modelValue.value.alertCounts)

const totalAlertCount = computed(() => {
  return alertCounts.value
    ? Object.values(alertCounts.value).reduce((a, b) => a + b, 0)
    : 0
})

const recommendedFlowCardHeading = computed(() =>
  totalAlertCount.value === 0 ? 'Keep your business safe' : 'Top alerts found',
)
const signupCardIcon = computed<IconName>(() =>
  totalAlertCount.value === 0 ? 'shield' : 'triangle-exclamation',
)
const signupCardText = computed(() =>
  totalAlertCount.value === 0
    ? 'Manage your security with Atro to stay secure'
    : 'See all of your alerts and start reducing your risk',
)

function onClaimAccount() {
  emit('action', {
    type: 'claim_account',
  })
}
</script>
