<template>
  <AtroContent
    shrink
    class="relative h-full w-full bg-white"
    direction="col"
    items="center"
  >
    <Transition name="fade">
      <AtroContent
        v-if="showSkipConfirm"
        class="absolute inset-0 z-1 bg-white"
        direction="col"
        items="center"
        justify="center"
      >
        <AtroContent
          class="space-y-3"
          direction="col"
          items="center"
          justify="center"
        >
          <AtroIconWrap
            circle
            class="text-primary"
            name="forward"
            type="dark"
          />
          <AtroHeading
            semibold
            class="text-atro-slate-purple"
            size="xl"
            text="Skip walkthrough?"
          />
          <AtroSpan
            class="text-atro-gray"
            text="You can restart settings if you change your mind"
          />
        </AtroContent>
        <AtroContent
          class="mt-14 space-y-6"
          direction="col"
          items="center"
          justify="center"
        >
          <AtroButton
            text="Continue Walkthrough"
            @click="showSkipConfirm = false"
          />
          <AtroButton text="Skip It" type="light" @click="onSkipOnboarding" />
          >
        </AtroContent>
      </AtroContent>
    </Transition>

    <AtroContent
      v-if="hasRemainingOnboardingTasks"
      shrink
      class="h-full w-full flex-1 overflow-auto"
      direction="col"
      wrap="nowrap"
    >
      <!-- HEADER -->
      <AtroContent
        class="bg-primary relative w-full space-y-2 p-6 text-center"
        direction="col"
        items="center"
        justify="center"
      >
        <AtroIconWrap
          circle
          :class="{ '!text-atro-green': lastCompletedTask }"
          :name="headerIcon"
        />
        <AtroHeading semibold class="text-white" :text="headerText" />
        <AtroSpan
          v-if="headerSubtext"
          class="text-white/75"
          :text="headerSubtext"
        />
        <OverflowMenu
          v-if="!welcome"
          class="!absolute right-4 top-2"
          menu-button-class="text-white hover:text-primary"
          size="sm"
          :items="overflowMenuItems"
          @action="onMenuAction"
        />
      </AtroContent>

      <!-- BODY -->
      <AtroContent class="w-full px-8 py-6" direction="col">
        <AtroContent class="w-full space-y-2" direction="col">
          <AtroSpan semibold class="text-atro-gray">To-do</AtroSpan>

          <OnboardingTaskItem
            v-for="(task, i) in onboardingTasks"
            :task
            :key="task.slug"
            :clickable="!welcome"
            :hide-bar="i === 0"
            :previous_task_completed="isTaskCompleted(onboardingTasks[i - 1])"
            @click="onItemClick(task)"
            @keyup.space="onItemClick(task)"
          />
        </AtroContent>
      </AtroContent>
    </AtroContent>

    <AtroContent
      v-else
      class="bg-primary h-full w-full flex-1 space-y-2 overflow-auto py-32"
      direction="col"
      items="center"
      justify="center"
    >
      <AtroIconWrap circle class="!text-atro-green" name="check" type="light" />
      <AtroHeading semibold class="text-center text-white"
        >Nice work!<br />
        You're done with the basics</AtroHeading
      >
    </AtroContent>

    <!-- Footer -->
    <AtroContent
      class="relative w-full space-y-2 bg-white p-6"
      direction="col"
      items="center"
      justify="center"
    >
      <template v-if="hasRemainingOnboardingTasks">
        <AtroButton
          icon-right="chevron-right"
          :text="ctaButtonText"
          @click="onCtaClick"
        />
        <AtroButton
          v-if="welcome"
          icon-right="chevron-right"
          text="Skip set up and explore Atro"
          type="transparent"
          @click="onSkipOnboarding"
        />
      </template>

      <template v-else>
        <AtroButton
          icon-right="magnifying-glass"
          text="Secure Something Else"
          @click="openMarketplace"
        />
        <AtroButton
          icon-right="chevron-right"
          text="Go to Dashboard"
          type="transparent"
          @click="navigateToWithOrg('')"
        />
      </template>
    </AtroContent>

    <Transition name="fade">
      <Confetti v-show="_showConfetti" type="container" />
    </Transition>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  showConfetti?: boolean
  welcome?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits<{
  'skip-onboarding': []
}>()

const { openMarketplace } = useMarketplace()
const {
  isTaskCompleted,
  hasRemainingOnboardingTasks,
  lastCompletedTask,
  jumpToTask,
  nextTask,
  onboardingTasks,
  skipOnboarding,
} = useOnboarding()

const overflowMenuItems: MenuItem[] = [
  {
    action: 'skip',
    label: 'Skip Walkthrough',
  },
]
const showSkipConfirm = ref(false)

const ctaButtonText = computed(() =>
  showInitialState.value ? 'Get Started' : 'Set up Next',
)
const headerIcon = computed<IconName>(() =>
  !lastCompletedTask.value ? 'list-check' : 'check',
)
const headerText = computed(() =>
  !lastCompletedTask.value?.headerText
    ? 'Let’s get started securing your business!'
    : lastCompletedTask.value?.headerText,
)
const headerSubtext = computed(() =>
  !lastCompletedTask.value?.headerSubtext
    ? 'We’ll walk you though foundational security to protect your devices and data.'
    : lastCompletedTask.value?.headerSubtext,
)
const showInitialState = computed(() => !lastCompletedTask.value)
const _showConfetti = computed(
  () => props.showConfetti || !hasRemainingOnboardingTasks.value,
)

function onCtaClick() {
  nextTask()
}

function onItemClick(task: OnboardingTask) {
  !props.welcome && jumpToTask(task)
}

function onMenuAction(action: string) {
  switch (action) {
    case 'skip':
      showSkipConfirm.value = true
      break
    default:
      break
  }
}

function onSkipOnboarding() {
  skipOnboarding()
  emit('skip-onboarding')
}
</script>
