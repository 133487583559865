<template>
  <div class="w-full">
    <AtroSection title="Team Breakdown">
      <template #postfix>
        <div class="space-x-1">
          <AtroSpan
            semibold
            class="text-atro-gray"
            size="sm"
            text="Email Frequency"
          />
          <AtroSpan
            semibold
            class="text-atro-slate-purple"
            size="sm"
            :text="intensity"
          />
        </div>
      </template>
      <!-- BODY -->
      <template #default>
        <TeamPhishingReportTable
          :user-phishing-reports
          @send-test="onSendTest"
        />
      </template>
    </AtroSection>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  intensity: string
  userPhishingReports: UserPhishingReport[]
}

defineProps<Props>()

const emit = defineEmits<{
  action: [action: FlowAction]
}>()

function onSendTest(userId: string) {
  emit('action', {
    type: 'send_action',
    data: {
      actionSlug: 'queue_email',
      body: { userId },
    },
  })
}
</script>
