export const createOnboardFlow = async ({
  body,
}: {
  body: { flowId: string }
}) => {
  const path = getApiPath('onboardingFlows')

  return await $post<OnboardingFlowResponse>(path, body)
}

export const createOrgFlow = async ({
  body,
  params,
}: {
  body: { flowId: string }
  params: Parameters<typeof apiPaths.orgFlows>[0]
}) => {
  const path = getApiPath('orgFlows', params)

  return await $post<OrgFlow>(path, body)
}

export const deleteOrgFlow = async ({
  body,
  params,
}: {
  body: string
  params: Parameters<typeof apiPaths.orgFlows>[0]
}) => {
  const key = getApiPath('orgFlows', params)
  const path = getApiPath('orgFlow', { ...params, id: body })

  return await deleteResource(
    path,
    {
      key,
      id: body,
    },
    // custom remove function is required because org flow api response has a nested structure
    (cache, id) => {
      const itemIndex = cache.value.orgFlows?.findIndex(
        (item: any) => item.id === id,
      )
      typeof itemIndex === 'number' &&
        cache.value.orgFlows?.splice(itemIndex, 1)
    },
  )
}

export const restartOrgFlow = async ({
  body,
  params,
}: {
  body: string
  params: { orgId: string }
}) => {
  const path = getApiPath('restartOrgFlow', { id: body, ...params })

  return await $post<OrgFlow>(path, body)
}

export const saveOrgFlowData = async ({
  body,
  params,
  query,
}: {
  body: OrgFlow
  params: Parameters<typeof apiPaths.orgFlow>[0]
  query: {
    page: string
  }
}) => {
  const cachePath = getApiPath('orgFlow', params)
  const path = getApiPath('orgFlow', params, query)

  const { data: orgFlow } = useNuxtData<OrgFlow>(cachePath)
  const response = await $patch<OrgFlow>(path, body)
  if (orgFlow.value) mergeDeep(orgFlow.value, response)

  return response
}

export const saveUserFlowData = async ({
  body,
  params,
  query,
}: {
  body: OrgFlow
  params: Parameters<typeof apiPaths.userFlow>[0]
  query: {
    pageSlug: string
  }
}) => {
  const cachePath = getApiPath('userFlow', params)
  const path = getApiPath('userFlow', params, query)

  const { data: orgFlow } = useNuxtData<UserFlow>(cachePath)
  const response = await $patch<UserFlow>(path, body)
  if (orgFlow.value) mergeDeep(orgFlow.value, response)

  return response
}

export const sendOrgFlowAction = async ({
  body,
  params,
}: {
  body: Record<string, unknown>
  params: Parameters<typeof apiPaths.sendOrgFlowAction>[0]
}) => {
  const path = getApiPath('sendOrgFlowAction', params)

  return await $post(path, body)
}

export const sendUserFlowAction = async ({
  body,
  params,
}: {
  body: Record<string, unknown>
  params: Parameters<typeof apiPaths.sendUserFlowAction>[0]
}) => {
  const path = getApiPath('sendUserFlowAction', params)

  return await $patch(path, body)
}
