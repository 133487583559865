<template>
  <AtroContent
    :class="[
      'fixed left-0 top-0 z-max h-full w-full bg-white/95 p-6 backdrop-blur-sm transition-all duration-500 ease-in-out',
      {
        '-translate-x-full opacity-50': !mobileNavShown,
        'translate-x-0 opacity-100': mobileNavShown,
      },
    ]"
    direction="col"
    justify="between"
  >
    <AtroContent class="w-full" direction="col" wrap="nowrap">
      <div class="relative text-primary">
        <AtroIcon class="h-6 w-24 text-primary" name="atro-logo-horizontal" />
        <AtroSpan v-if="isPreview" semibold size="xs">
          Preview: {{ previewBranchName }}
        </AtroSpan>
        <AtroHeading
          v-if="isPartnerView"
          class="mt-4 font-light text-atro-gray"
          size="sm"
          text="Partner Dashboard"
        />
      </div>
    </AtroContent>

    <AtroContent class="w-full">
      <!-- Profile -->
      <AtroContent
        class="cursor-pointer py-2 text-primary hover:text-primary-75"
        items="center"
        @click="() => navigateToWithOrg('/manage/profile')"
      >
        <UserAvatar
          class="mr-1.5 sm:mr-1"
          :size="$isMobile.value ? 'md' : 'sm'"
          :user="user"
        />
        <AtroSpan semibold text="My profile" />
      </AtroContent>
      <AtroContent
        v-for="menuItem in menuItems"
        :key="menuItem.label"
        class="w-full"
      >
        <AtroMenuItem
          class="text-primary hover:text-primary-75"
          :is-mobile="$isMobile.value"
          :item="menuItem"
          @click="onMenuItemClick(menuItem)"
        />
      </AtroContent>
    </AtroContent>

    <AtroCloseButton
      class="!absolute right-4 top-4 z-1 !p-2"
      @click="closeDrawer()"
    />
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  view?: 'default' | 'partner'
}

const { view = 'default' } = defineProps<Props>()

const { user } = useCurrentUser()
const { closeDrawer, mobileNavShown } = useMobileDrawer()
const { menuItems, onAction } = useUserNav()
const { isPreview, previewBranchName } = useVercelPreview()

const isPartnerView = computed(() => view === 'partner')

watch(mobileNavShown, (isOpen) => {
  isOpen
    ? document.querySelector('body')?.classList.add('overflow-hidden')
    : document.querySelector('body')?.classList.remove('overflow-hidden')
})

function onMenuItemClick(menuItem: MenuItem) {
  if (menuItem.action) onAction(menuItem.action)
  else if (menuItem.linkTo) navigateTo(menuItem.linkTo)
  closeDrawer()
}
</script>
