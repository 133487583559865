<template>
  <NuxtLink :to="linkTo">
    <AtroGridTableCell
      :class="[
        'group/cell justify-center',
        {
          'cursor-pointer': isClickable,
          'h-10': size === 'md',
          'h-[28px]': size === 'sm',
        },
      ]"
    >
      <AtroContent
        :class="[
          {
            'transition-opacity group-hover/cell:opacity-0 group-hover:delay-100':
              isClickable,
            [scoreBgColorClass]: hasData,
            'rounded-full text-white': hasData,
            'px-2 py-0.5': hasData && size === 'sm',
            'px-3 py-2': hasData && size === 'md',
            'text-atro-slate-purple': !hasData,
          },
        ]"
        items="center"
        justify="center"
      >
        <AtroSpan
          class="capitalize"
          :semibold="hasData"
          :size="size === 'md' ? 'md' : 'xs'"
          :text="scoreText"
        />
      </AtroContent>
      <AtroButton
        v-if="isClickable"
        class="!absolute opacity-0 group-hover/cell:opacity-100 group-hover:delay-100"
        icon-right="chevron-right"
        type="flat"
        :text="hoverText"
      />
    </AtroGridTableCell>
  </NuxtLink>
</template>

<script setup lang="ts">
import { getScoreColor, getRiskColor } from '@atro/components'
import { RouteLocationRaw } from '#vue-router'

export interface Props {
  flow?: OrgFlow
  flowPrototype?: OrgFlow
  org?: Org
  riskLevel?: RiskLevel
  score?: number
  size?: 'sm' | 'md'
}

const {
  flow,
  flowPrototype,
  org,
  riskLevel,
  score,
  size = 'md',
} = defineProps<Props>()
const { getMarketplaceDetailLinkTo } = useMarketplace()

const hasData = computed(
  () => (score !== undefined && score !== null) || !!riskLevel,
)
const isClickable = computed(() => !!org)

const linkTo = computed<RouteLocationRaw>(() => {
  if (!isClickable.value) return null
  if (!flow && flowPrototype) {
    return {
      path: '/org-switch',
      query: {
        forwardPath: '/',
        forwardQuery: JSON.stringify(
          getMarketplaceDetailLinkTo(flowPrototype.slug).query,
        ),
        org: org?.slug,
      },
    }
  } else if (flow?.status === 'started') {
    return {
      path: '/org-switch',
      query: {
        forwardPath: `/org-flows/${flow?.id}/setup`,
        org: org?.slug,
      },
    }
  } else if (!flow && !flowPrototype) {
    return {
      path: '/org-switch',
      query: {
        forwardPath: '/',
        org: org?.slug,
      },
    }
  } else {
    return {
      path: '/org-switch',
      query: {
        forwardPath: `/org-flows/${flow?.id}`,
        org: org?.slug,
      },
    }
  }
})
const scoreBgColorClass = computed(() => {
  if (!hasData.value) {
    return 'bg-atro-gray-25'
  } else if (riskLevel) {
    return getRiskColor(riskLevel, 'bg')
  } else {
    return getScoreColor(score, 'bg')
  }
})

const scoreText = computed(() => {
  if (hasData.value) return riskLevel || score?.toString()
  else {
    if (!flow && flowPrototype) return 'Not installed'
    else if (flow?.status === 'started') return 'Needs setup'
    else return 'Waiting'
  }
})
const hoverText = computed(() => {
  if (!flow && flowPrototype) return 'Install App'
  else if (flow?.status === 'started') return 'Setup App'
  else if (!flow && !flowPrototype) return 'View Org'
  else return 'View App'
})
</script>
