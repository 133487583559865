<template>
  <AtroContent shrink items="center" wrap="nowrap">
    <AtroSwitch
      v-bind="context.attrs.props"
      :help="context.help"
      :label="context.attrs.text"
      :label-position="context.attrs['text-position']"
      :label-semibold="context.attrs['text-semibold']"
      :model-value="context._value"
      @input="context.node.input"
    >
      <template v-if="iconLeft" #labelPrefix>
        <AtroIcon class="mr-3 h-4 w-4" :name="iconLeft" />
      </template>
    </AtroSwitch>
  </AtroContent>
</template>

<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'

export interface Props {
  context: FormKitFrameworkContext
}

const { context } = defineProps<Props>()

const iconLeft = computed<IconName>(() => context.attrs['icon-left'])
</script>
