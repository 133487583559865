import { compareDesc, isAfter } from 'date-fns'

export function useReleaseNotes() {
  const { user, isAdminOrOwner } = useCurrentUser()
  const { uiContent, uiOrgState, uiUserState, setUiState } = useUiConfig()
  const userJoinedAt = computed(() => new Date(user.value?.confirmedAt))

  const seenReleases = computed(
    () =>
      (isAdminOrOwner.value
        ? uiOrgState.value?.releaseNotes?.seen
        : uiUserState.value?.releaseNotes?.seen) || [],
  )
  const releaseNotes = computed(() =>
    isAdminOrOwner.value ? uiContent.value?.releaseNotes?.releases || [] : [],
  )
  const releasesAfterJoining = computed(() =>
    releaseNotes.value.filter((releaseNote) =>
      isAfter(new Date(releaseNote.timestamp), userJoinedAt.value),
    ),
  )
  const unreadReleases = computed(() =>
    releasesAfterJoining.value
      .filter(
        (releaseAfterJoining) =>
          !seenReleases.value.includes(releaseAfterJoining.releaseNumber),
      )
      .sort((a, b) =>
        compareDesc(new Date(a.timestamp), new Date(b.timestamp)),
      ),
  )

  function markReleasesAsSeen(releases: string[]) {
    const updatedSeenReleases = new Set([...seenReleases.value, ...releases])
    setUiState('org', 'releaseNotes.seen', Array.from(updatedSeenReleases))
  }

  return {
    markReleasesAsSeen,
    unreadReleases,
  }
}
