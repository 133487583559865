<template>
  <FormKit type="group" v-model="model">
    <FormKitSchema :schema="_schema" />
    <AtroButton
      :icon-left
      class="place-self-start"
      type="flat"
      :disabled="!canAdd"
      :text="context.attrs.addLabel || 'Add Another'"
      @click.stop.prevent="addItem"
    />
  </FormKit>
</template>

<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'

export interface Props {
  context: FormKitFrameworkContext
}

const { context } = defineProps<Props>()

const _schema = ref([])

const canAdd = computed(() =>
  context.attrs.maxItems
    ? _schema.value?.length < context.attrs.maxItems
    : true,
)
const iconLeft = computed<IconName>(
  () => (context.addIcon as IconName) || 'plus',
)
const repeatedSchema = computed(() => {
  const _repeatedSchema = context.attrs.repeatedSchema
  _repeatedSchema.$formkit = _repeatedSchema.formkit
  delete _repeatedSchema.formkit
  return _repeatedSchema
})

const model = computed({
  get() {
    const _model =
      context.value?.reduce((data, value, i) => {
        data[i] = value
        return data
      }, {}) || {}
    return _model
  },
  set(value) {
    const valuesArray = Object.keys(value).map((key) => value[key])
    if (JSON.stringify(valuesArray) !== JSON.stringify(context.node.value))
      context.node.input(valuesArray)
  },
})

function addItem() {
  if (canAdd.value) _schema.value.push(getSchemaItem(_schema.value.length))
}

function getSchemaItem(i: number) {
  return {
    ...repeatedSchema.value,
    ...(repeatedSchema.value.label
      ? { label: `${repeatedSchema.value.label} ${i + 1}` }
      : {}),
    name: i.toString(),
    classes: {
      outer: 'w-full',
    },
    __raw__sectionsSchema: {
      suffix: {
        $formkit: 'button',
        label: ' ',
        'prefix-icon': 'xmark',
        'data-index': i,
        onClick: (e) => {
          removeItem(Number(e.currentTarget.dataset.index))
        },
        classes: {
          prefixIcon: '!size-4',
          outer: 'mr-4',
        },
      },
    },
  }
}

async function removeItem(i: number) {
  if (_schema.value.length > 1) {
    const newModel = { ...model.value }
    delete newModel[i]
    model.value = newModel
    await wait(10)
    _schema.value.splice(i, 1)
    _schema.value.forEach((item, i) => {
      item['data-index'] = i
      if (repeatedSchema.value.label)
        item.label = `${repeatedSchema.value.label} ${i + 1}`
    })
  }
}

function setSchema() {
  _schema.value = context.value?.length
    ? context.value.reduce((schema, value, i) => {
        schema.push(getSchemaItem(i))
        return schema
      }, [])
    : [getSchemaItem(0)]
}

onMounted(setSchema)
</script>
