<template>
  <Form
    :id
    v-model="model"
    :actions="false"
    :schema="_schema"
    @submit="(data) => $emit('action', { type: 'submit', data })"
  />
</template>

<script setup lang="ts">
import type {
  FormKitNode,
  FormKitFrameworkContext,
  FormKitSchemaNode,
} from '@formkit/core'
import dot from 'dot-object'
import { getNode } from '@formkit/core'
import { toCamelCase } from 'js-convert-case'

export interface Props {
  modelValue: any
  schema: FormKitSchemaNode[]

  data?: Record<string, any>
  deps?: Record<string, any>
}

const props = defineProps<Props>()
const { schema } = props
const emit = defineEmits<{
  action: [FlowAction]
  validityChange: [valid: boolean]
}>()
const model = defineModel<Props['modelValue']>()

const { registerPageForm } = usePageState()

const id = useId()
const validity = ref()

const _schema = computed(() => schema.reduce(fixSchemaNode, []))

function fixSchemaNode(currentSchema: any[], schemaNode: any) {
  let shouldShow = true
  const schemaNodeCopy = { ...schemaNode }
  if (schemaNodeCopy.name) {
    schemaNodeCopy.name = toCamelCase(schemaNodeCopy.name)
  }
  if (schemaNodeCopy.el) {
    schemaNodeCopy.$el = schemaNodeCopy.el
    delete schemaNodeCopy.el
  }
  if (schemaNodeCopy.cmp) {
    schemaNodeCopy.$cmp = schemaNodeCopy.cmp
    delete schemaNodeCopy.cmp
  }
  if (schemaNodeCopy.formkit) {
    schemaNodeCopy.$formkit = schemaNodeCopy.formkit
    delete schemaNodeCopy.formkit
  }
  if (schemaNodeCopy.children) {
    schemaNodeCopy.children = schemaNodeCopy.children.reduce(fixSchemaNode, [])
  }
  if (typeof schemaNodeCopy.show !== 'undefined') {
    if (typeof schemaNodeCopy.show === 'string') {
      shouldShow = Boolean(dot.pick(schemaNodeCopy.show, props))
    } else if (typeof schemaNodeCopy.show === 'boolean') {
      shouldShow = schemaNodeCopy.show
    }
  }

  shouldShow && currentSchema.push(schemaNodeCopy)
  return currentSchema
}

onMounted(() => {
  const form = getNode(id) as FormKitNode
  registerPageForm(form)

  watch(
    form.context as FormKitFrameworkContext,
    () => {
      if (validity.value !== !!form.context?.state.valid) {
        validity.value = !!form.context?.state.valid
        emit('validityChange', validity.value)
      }
    },
    { immediate: true },
  )
})
</script>
