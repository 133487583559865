type GlobalState = {
  appBannerShown?: boolean
  bottomToolbarActive?: boolean
  flowCompleted?: boolean
  logrocketSessionUrl?: string
  onboardingWidgetConfetti?: boolean
  showOnboardingWidget?: boolean
  upgradeModalShown?: boolean
}

export default function useGlobalState() {
  const globalState = useState('globalState', () => ({}) as GlobalState)
  return globalState
}
