<template>
  <AtroContent
    class="group relative w-full"
    items="center"
    wrap="nowrap"
    @click="onCopy"
  >
    <FormKit
      type="text"
      input-class="font-semibold text-center !cursor-pointer"
      inner-class="rounded-r-none"
      outer-class="w-full"
      :placeholder="context.attrs.placeholder"
      :readonly="true"
      :value="context.value"
    />
    <AtroButton
      class="h-full rounded-l-none bg-primary/5 !px-2 text-primary hover:!bg-primary/20 focus-visible:bg-primary/20 group-hover:bg-primary/10"
      icon-right="copy"
      text="Copy"
      type="blank"
      @click.prevent.capture="() => {}"
    />
    <Transition name="fade">
      <AtroContent
        v-if="copied"
        class="absolute inset-0 bg-white/90"
        items="center"
        justify="center"
      >
        <AtroSpan semibold text="Copied!" />
      </AtroContent>
    </Transition>
  </AtroContent>
</template>

<script setup lang="ts">
import type { FormKitFrameworkContext } from '@formkit/core'

export interface Props {
  context: FormKitFrameworkContext
}

const { context } = defineProps<Props>()
const copyText = useCopyText()
const copied = ref(false)

function onCopy() {
  copyText(context.value)
  copied.value = true
  setTimeout(() => {
    copied.value = false
  }, 3000)
}
</script>
