<template>
  <AtroContent class="w-full space-y-2" direction="col">
    <FlowAtomOptionsGroup
      v-for="(optionsGroup, i) in optionsConfig"
      v-bind="optionsGroup"
      :key="optionsGroup.key"
      @update:model-value="(value) => setModel(optionsGroup.key, value)"
    />
  </AtroContent>
</template>

<script setup lang="ts">
import { toCamelCase } from 'js-convert-case'

export interface Props {
  optionsGroups: FlowAtomOptionsGroup[]

  modelValue?: Record<string, unknown>
  required?: boolean
}

const { modelValue, optionsGroups, required } = defineProps<Props>()
const emit = defineEmits<{
  'update:modelValue': [model: Props['modelValue']]
  validityChange: [valid: boolean]
}>()
const model = ref(modelValue || {})

const optionsConfig = computed(() => {
  return optionsGroups.map((optionsGroup, i) => {
    const modelKey = toCamelCase(optionsGroup?.modelValue) || i.toString()
    return {
      ...optionsGroup,
      key: modelKey,
      modelValue: model.value?.[modelKey],
    }
  })
})

function setModel(key: string, value: any) {
  // @ts-ignore
  model.value = {
    ...model.value,
    [key]: value,
  }
  emit('update:modelValue', model.value)
}

// grouping is considered "valid" if all options have a non null and defined value
watchEffect(() => {
  if (required && model.value) {
    const isValid = optionsConfig.value.every((optionConfig) => {
      if (optionConfig.correctOption) {
        return model.value?.[optionConfig.key] === optionConfig.correctOption
      } else {
        return model.value?.[optionConfig.key]
      }
    })
    emit('validityChange', isValid)
  }
})
</script>
