<template>
  <button
    v-auto-animate
    :class="[
      'transition-all focus-visible:ring-2 disabled:opacity-50',
      {
        'rounded-[48px] px-4 py-2 font-semibold': type === 'bubble',
        'text-md rounded-lg border-2 border-transparent p-2 text-left text-atro-gray-2':
          type === 'text',
        'bg-atro-green-15-15 border-atro-green bg-atro-green/10':
          type === 'text' && correct,
        'border-atro-red bg-atro-red-15': type === 'text' && incorrect,
        'hover:bg-primary/5 hover:text-primary':
          type === 'text' && !incorrect && !correct,
        'bg-primary text-white ring-inset ring-white enabled:hover:bg-primary-75':
          active && type === 'bubble',
        'bg-white text-primary ring-primary enabled:hover:bg-primary/25':
          !active && type === 'bubble',
      },
    ]"
  >
    <AtroContent class="w-full" wrap="nowrap">
      <AtroIcon
        v-if="type === 'text'"
        :class="[
          'mr-2 mt-1 h-4 w-4',
          {
            'text-atro-gray-2': !incorrect && !correct,
            'text-atro-green': correct,
            '!text-atro-red': incorrect,
          },
        ]"
        :name="radioIcon"
      />
      <AtroMarkdown :markdown="option.label" />
    </AtroContent>

    <AtroParagraph
      v-if="(incorrect || correct) && type === 'text'"
      :class="[
        'ml-6 mt-2',
        {
          'text-atro-gray-2': !incorrect && !correct,
          'text-atro-green': correct,
          'text-atro-red': incorrect,
        },
      ]"
      :text="resultsText"
    />
  </button>
</template>

<script setup lang="ts">
export interface Props {
  option: SelectOption

  active?: boolean
  correct?: boolean
  incorrect?: boolean
  type?: 'bubble' | 'text'
}

const { correct, incorrect, option } = defineProps<Props>()

const radioIcon = computed<IconName>(() => {
  if (correct) {
    return 'circle-check-outline'
  } else if (incorrect) {
    return 'circle-xmark-outline'
  } else {
    return 'circle'
  }
})

const resultsText = computed(() => {
  if (correct) {
    return option.answeredText || 'Correct!'
  } else {
    return option.answeredText || 'Try again!'
  }
})
</script>
