import hash from 'object-hash'
// GENERAL MODALS
import ModalAction from '~/components/modal/ModalAction.vue'
import ModalChangeEmail from '~/components/modal/ModalChangeEmail.vue'
import ModalChangePassword from '~/components/modal/ModalChangePassword.vue'
import ModalConfirm from '~/components/modal/ModalConfirm.vue'
import ModalCreateProspect from '~/components/modal/ModalCreateProspect.vue'
import ModalDelete from '~/components/modal/ModalDelete.vue'
import ModalFlowMarketplace from '~/components/modal/ModalFlowMarketplace.vue'
import ModalFlowPage from '~/components/modal/ModalFlowPage.vue'
import ModalImportUsers from '~/components/modal/ModalImportUsers.vue'
import ModalOnboardingWelcome from '~/components/modal/ModalOnboardingWelcome.vue'
import ModalOrgEdit from '~/components/modal/ModalOrgEdit.vue'
import ModalOrgSwitcher from '~/components/modal/ModalOrgSwitcher.vue'
import ModalPolicyTemplate from '~/components/modal/ModalPolicyTemplate.vue'
import ModalReleaseNotes from '~/components/modal/ModalReleaseNotes.vue'
import ModalSubscriptionFreeTrial from '~/components/modal/ModalSubscriptionFreeTrial.vue'
import ModalSubscriptionProInfo from '~/components/modal/ModalSubscriptionProInfo.vue'
import ModalSubscriptionUpgrade from '~/components/modal/ModalSubscriptionUpgrade.vue'
import ModalTeamInvite from '~/components/modal/ModalTeamInvite.vue'
import ModalUserProfile from '~/components/modal/ModalUserProfile.vue'
// FLOW MODALS
import FlowModalManageConnection from '~/components/flow/modal/FlowModalManageConnection.vue'

const MODAL_MAP = {
  actionModal: ModalAction,
  changeEmail: ModalChangeEmail,
  changePassword: ModalChangePassword,
  confirm: ModalConfirm,
  createProspect: ModalCreateProspect,
  delete: ModalDelete,
  flowMarketplace: ModalFlowMarketplace,
  flowPage: ModalFlowPage,
  importUsers: ModalImportUsers,
  manageConnection: FlowModalManageConnection,
  onboardingWelcome: ModalOnboardingWelcome,
  orgEdit: ModalOrgEdit,
  orgSwitcher: ModalOrgSwitcher,
  policyTemplate: ModalPolicyTemplate,
  releaseNotes: ModalReleaseNotes,
  subscriptionFreeTial: ModalSubscriptionFreeTrial,
  subscriptionProInfo: ModalSubscriptionProInfo,
  subscriptionUpgrade: ModalSubscriptionUpgrade,
  teamInviteModal: ModalTeamInvite,
  userProfile: ModalUserProfile,
}

export type ModalName = keyof typeof MODAL_MAP
type ModalState = {
  component: any
  hash: string
  isOpen: boolean
  modalName: ModalName

  options: {
    afterClose?: () => void
    deep?: boolean
    eventHandlers?: Record<string, (payload?: any) => any>
    props?: Record<string, unknown>
    replace?: boolean
    updateInPlace?: boolean
  }
}

const modalStack = ref<ModalState[]>([])

export function useModal() {
  const router = useRouter()

  const currentModal = computed(
    () => modalStack.value[modalStack.value.length - 1],
  )
  const currentModalOptions = computed(() => currentModal.value.options)

  function afterClose() {
    currentModalOptions.value.afterClose &&
      currentModalOptions.value.afterClose()
    // remove closed modal from stack
    modalStack.value = modalStack.value.slice(0, -1)
  }

  function closeModal() {
    if (currentModal.value) {
      currentModal.value.isOpen = false
      if (router.currentRoute.value.query.modal) {
        removeQueryParam(router.currentRoute.value, ['modal', 'options'])
      }
    }
  }

  function storeDeepModal(modal: ModalName, options: ModalState['options']) {
    navigateTo(getModalDeepLinkTo(modal, options), {
      replace: options?.replace,
    })
  }

  function getModalDeepLinkTo(
    modal: ModalName,
    options: ModalState['options'],
  ) {
    return {
      query: {
        modal,
        ...(options ? { options: JSON.stringify(options) } : {}),
      },
    }
  }

  function handleModalEvent(type: string, payload: any) {
    const handler = currentModalOptions.value.eventHandlers?.[type]
    if (handler) {
      handler(payload)
    } else {
      console.error(`Modal is missing event hanlder: ${type}`)
    }
  }

  const openModal = useThrottleFn(
    async (modal: ModalName, options: ModalState['options'] = {}) => {
      const modalComponent = MODAL_MAP[modal]
      const newHash = hash({ modal, props: Object.keys(options.props || {}) })
      if (import.meta.client && modalComponent) {
        options?.deep && storeDeepModal(modal, options)
        if (modal === currentModal.value?.modalName && options.updateInPlace) {
          currentModalOptions.value.props = options.props
        } else {
          const modalState: ModalState = {
            options,
            component: modalComponent,
            hash: newHash,
            isOpen: true,
            modalName: modal,
          }
          modalStack.value = [...modalStack.value, modalState]
        }
      } else if (!modalComponent) {
        console.warn('Modal missing:', modal)
      }
    },
    100,
  )

  return {
    currentModal,
    modalStack,
    afterClose,
    closeModal,
    handleModalEvent,
    getModalDeepLinkTo,
    openModal,
  }
}
