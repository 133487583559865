<template>
  <AtroContent>
    <AtroHeading
      semibold
      class="mb-6 text-center text-atro-gray-1"
      text="Tell us about you"
    />

    <Form
      v-model="formData"
      actions-class="justify-center"
      input-width="full"
      @submit="onSubmit"
    >
      <FormKit
        autofocus
        type="text"
        name="firstName"
        label="First name"
        validation="required"
      />
      <FormKit
        type="text"
        name="lastName"
        label="Last name"
        validation="required"
      />

      <template #submit="{ state: { valid } }">
        <AtroButton :pending text="Continue" :disabled="!valid" />
      </template>
    </Form>
  </AtroContent>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  complete: []
}>()

const formData = ref({
  firstName: '',
  lastName: '',
})

const { onSubmit, pending } = useFormSubmit<typeof formData.value>(
  async (data) => {
    await $patch('/api/profile', data)
    emit('complete')
  },
)
</script>
