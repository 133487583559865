<template>
  <AtroContent
    class="font-semibold text-atro-gray-2 transition-colors hover:text-atro-gray"
  >
    <AtroContent
      v-if="type === 'toggle_item'"
      class="w-full cursor-pointer space-x-2"
      items="center"
    >
      <AtroContent items="center">
        <AtroSwitch
          v-model="modelValue"
          :action
          label-position="left"
          :label="title"
          @action="onAction"
        >
          <template #labelPrefix>
            <AtroIcon class="mr-2 h-4 w-4" name="sparkles" />
          </template>

          <template #label>
            <AtroMarkdown :markdown="title" />
          </template>
        </AtroSwitch>
        <FlowAtomInfoTooltip v-if="tooltip" :tooltip class="ml-3" />
      </AtroContent>
    </AtroContent>

    <AtroContent
      v-else-if="type === 'text_item'"
      class="w-full space-x-2 text-atro-gray"
      items="center"
      wrap="nowrap"
    >
      <AtroIcon v-if="icon" class="h-4 w-4" :name="icon" />
      <AtroMarkdown :markdown="title" />
    </AtroContent>

    <AtroButton
      v-else
      type="flatGray"
      :icon-left="icon"
      @click="onAction({ type: action as FlowActionType, data: actionTarget })"
    >
      <AtroMarkdown :markdown="title" />
    </AtroButton>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  icon: IconName
  title: string

  action?: FlowAction | FlowActionType
  actionTarget?: string
  modelValue?: boolean
  tooltip?: FlowTooltip
  type?: 'sidebar_item' | 'text_item' | 'toggle_item'
}

const props = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
  'update:modelValue': [model: Props['modelValue']]
}>()
const modelValue = useVModel(props, 'modelValue', emit)
const { action, type } = props

function onAction(action: FlowAction) {
  emit('action', action)
}
</script>
