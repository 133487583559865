export const sendInvites = async ({
  body,
  params,
}: {
  body: { role: Role; emails: string[] }
  params: Parameters<typeof apiPaths.orgUsers>[0]
}) => {
  const path = apiPaths.orgUsers(params)
  if (!path) return

  const { data: users } = useNuxtData<User[]>(path)
  const response = await $post<User[]>(path, body)
  response.forEach((user) => {
    const existingUserId = (users.value || []).findIndex(
      (existingUser) => existingUser.id === user.id,
    )
    if (existingUserId >= 0) {
      users.value?.splice(existingUserId, 1, user)
    } else {
      users.value?.push(user)
    }
  })

  return response
}

export const deleteUser = async ({
  body,
  params,
}: {
  body: string
  params: Parameters<typeof apiPaths.orgUser>[0]
}) => {
  const usersPath = getApiPath('orgUsers', params)
  const path = getApiPath('orgUser', { ...params, userId: body })

  return deleteResource(path, {
    id: body,
    key: usersPath,
  })
}

export const updateProfile = async ({ body }: { body: User }) => {
  const path = apiPaths.profile()
  return $patch<User>(path, body)
}

export const updateUser = async ({
  body,
  params,
}: {
  body: User
  params: Parameters<typeof apiPaths.orgUser>[0]
}) => {
  const usersPath = getApiPath('orgUsers', params)
  const path = getApiPath('orgUser', { ...params, userId: body.id })

  return updateResource<User>(path, {
    body,
    key: usersPath,
  })
}
