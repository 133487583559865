<template>
  <AppBanner
    :class="{
      'banner-gradient text-white': isTrial && !isFreePlan,
      'banner-gradient__light text-primary': isFreePlan,
    }"
    @click="openModal('subscriptionUpgrade')"
  >
    <AtroContent class="h-full w-full" items="center" justify="between">
      <AtroContent items="center">
        <AtroIcon class="mr-2 h-4 w-4" name="rocket-launch" />
        <AtroSpan size="sm" :text="trialText" />
      </AtroContent>
      <AtroButton
        class="bg-white/25 !px-3 !py-1 focus-visible:ring-2 focus-visible:ring-primary enabled:hover:opacity-50 enabled:hover:active:opacity-100"
        size="sm"
        text="Upgrade"
        type="blank"
      />
    </AtroContent>
  </AppBanner>
</template>

<script setup lang="ts">
const { isFreePlan, isTrial, isTrialExpired, trialRemaingDays } =
  useCurrentSubscription()
const { openModal } = useModal()

const trialText = computed(() => {
  if (isFreePlan.value) {
    return 'Upgrade to Atro Pro'
  } else {
    return isTrialExpired.value
      ? 'Your Pro trial has ended'
      : trialRemaingDays.value === '0 days'
        ? 'Your Pro trial ends today'
        : `Your Pro trial ends in ${trialRemaingDays.value}`
  }
})
</script>
