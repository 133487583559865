import { useStorage } from '@vueuse/core'
import { RudderAnalytics } from '@rudderstack/analytics-js'
import * as Sentry from '@sentry/vue'
import LogRocket from 'logrocket'

const identifyUserInTrackers = (
  analytics: Ref<RudderAnalytics>,
  user: User,
  org?: Org,
  campaign?: Record<string, string>,
) => {
  const userFields = {
    userId: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    username: user.displayName || '',
    email: user.email,
  }
  const orgFields = org
    ? {
        orgId: org.id,
        orgName: org.name,
        orgSlug: org.slug,
        orgSize: org.size,
        orgUserRole: org.userRole,
        ...(org.subscription
          ? {
              orgSubscriptionPlan: org.subscription.plan.slug,
              orgSubscriptionState: org.subscription.state,
            }
          : {}),
      }
    : {}

  try {
    analytics?.value?.identify(user.id, {
      ...campaign,
      ...orgFields,
      ...userFields,
    })
  } catch (e) {
    console.error('Analyitics identify error: ', e)
  }
  try {
    LogRocket.identify(user.id, userFields)
  } catch (e) {}
  try {
    Sentry.setUser({ id: user.id, ...userFields })
  } catch (e) {}
}

export default defineNuxtPlugin((nuxtApp) => {
  const globalState = useGlobalState()
  const router = useRouter()
  const runtimeConfig = useRuntimeConfig()
  const { user } = useCurrentUser()
  const { org } = useCurrentOrg()

  const analytics = ref<RudderAnalytics>()
  const campaign = useStorage('campaign', {})

  function identify() {
    if (import.meta.env.VITE_ENVIRONMENT !== 'development')
      identifyUserInTrackers(
        analytics as Ref<RudderAnalytics>,
        user.value,
        org.value,
        campaign.value,
      )
  }

  nuxtApp.hook('app:mounted', async () => {
    if (router.currentRoute.value.query.utm_campaign) {
      campaign.value = {
        utm_content: router.currentRoute.value.query.utm_content as string,
        utm_medium: router.currentRoute.value.query.utm_medium as string,
        utm_campaign: router.currentRoute.value.query.utm_campaign as string,
        utm_source: router.currentRoute.value.query.utm_source as string,
        utm_term: router.currentRoute.value.query.utm_term as string,
      }
    }

    if (import.meta.env.VITE_ENVIRONMENT !== 'development') {
      LogRocket.init(import.meta.env.VITE_LOGROCKET_ID as string, {
        release: runtimeConfig.public.GIT_RELEASE_SHA,
        network: {
          isEnabled: true,
        },
      })
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.getGlobalScope().setExtra('sessionURL', sessionURL)
      })

      window.rudderanalytics = new (
        await import('@rudderstack/analytics-js')
      ).RudderAnalytics()
      window.rudderanalytics.load(
        import.meta.env.VITE_RUDDER_WRITE_KEY as string,
        import.meta.env.VITE_RUDDER_DATA_PLANE_URL as string,
      )
      window.rudderanalytics.ready(() => {
        analytics.value = window.rudderanalytics as RudderAnalytics
        analytics.value?.page(router.currentRoute.value.name as string)

        LogRocket.getSessionURL(function (sessionURL) {
          globalState.value.logrocketSessionUrl = sessionURL
        })
        if (user.value) {
          identify()
        }
        router.afterEach((to) => {
          analytics.value?.page(to.name as string)
        })
      })
    } else {
      analytics.value = {
        track: () => null,
      } as any
    }
  })

  return {
    provide: {
      analytics,
      identify,
    },
  }
})
