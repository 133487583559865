<template>
  <AtroExpandableContent
    v-model="_expanded"
    class="w-full"
    justify="between"
    direction="col"
    wrap="nowrap"
  >
    <template #default="{ isExpanded, toggleExpand }">
      <AtroContent
        tabbable
        items="center"
        wrap="nowrap"
        :class="[
          'group w-full ring-inset transition-all',
          {
            'rounded-md px-2 py-1': inline,
            'bg-atro-warm-gray-2': color === 'dark',
            'bg-white/15': color === 'light',
            'p-3 sm:px-4 sm:py-3': !inline,
            'rounded-t-2xl': isExpanded && !inline,
            'rounded-2xl': !isExpanded && !inline,
            'cursor-pointer focus-visible:ring-2 active:bg-atro-warm-gray group-hover:ring-2 group-hover:ring-atro-warm-gray focus-visible:group-hover:ring-atro-focus-light':
              isClickable,
          },
        ]"
        @click="() => onActivateItem(toggleExpand)"
        @keyup.space="() => onActivateItem(toggleExpand)"
      >
        <AtroContent
          items="center"
          justify="between"
          wrap="nowrap"
          class="w-full"
        >
          <AtroContent shrink class="w-full" wrap="nowrap">
            <div v-if="isSelectable" :class="['mr-3', { 'mt-2.5': options }]">
              <FormKit
                v-if="!mutuallyExclusive"
                v-model="model"
                type="checkbox"
              />
              <input v-else type="radio" :checked="model" />
            </div>
            <AtroContent shrink class="w-full" wrap="nowrap" direction="col">
              <AtroContent
                :class="['w-full', itemStyleClasses]"
                items="center"
                justify="between"
                wrap="nowrap"
              >
                <AtroContent class="w-full" items="center" wrap="nowrap">
                  <AtroIcon
                    v-if="itemIconName"
                    :class="['mr-3', iconClasses]"
                    :name="itemIconName"
                  />
                  <AtroMarkdown :markdown="title" />
                </AtroContent>
              </AtroContent>
              <AtroMarkdown
                v-if="subTitle || subtitle"
                :class="[
                  'mt-2 text-sm',
                  {
                    'text-atro-gray': color === 'dark',
                    'text-white': color === 'light',
                  },
                ]"
                :markdown="subTitle || subtitle || ''"
              />
            </AtroContent>
          </AtroContent>

          <AtroContent
            v-if="showRightContent"
            :class="[
              'ml-6 space-x-4',
              { 'place-self-start': type === 'select' },
            ]"
            items="center"
          >
            <!-- BADGE -->
            <FlowAtomBadge v-if="badge" size="sm" v-bind="badge" />
            <!-- OVERFLOW MENU -->
            <OverflowMenu
              v-if="overflowMenu"
              v-bind="overflowMenu"
              :class="[
                'opacity-0 transition-all group-hover:opacity-100',
                { 'opacity-100': isExpanded },
              ]"
              @action="(action) => onAction(action)"
            />
            <!-- ITEM ACTION -->
            <AtroButton
              v-if="itemActionLabel && !expandable"
              class="pointer-events-none opacity-0 group-hover:pointer-events-auto group-hover:opacity-100"
              type="flat"
              @click.stop="itemAction && onAction(itemAction)"
              :text="itemActionLabel"
            />
            <!-- OPTIONS -->
            <AtroContent v-if="options">
              <FormKit
                :options
                v-model="selectedOption"
                id="optionsSelect"
                type="select"
                :placeholder="optionsPlaceholder || 'Select option'"
              />
            </AtroContent>
            <!-- RISK PROFILE CHART -->
            <RiskProfileChart
              v-if="riskProfile"
              v-bind="riskProfile"
              size="sm"
              :mode="color"
            />
            <!-- EXPAND TOGGLE -->
            <AtroExpandToggle
              v-if="expandable"
              :model-value="isExpanded"
              @keyup.space.prevent="() => null"
            />
          </AtroContent>
        </AtroContent>
      </AtroContent>
    </template>

    <template #expanded-content>
      <FlowAtomListItemContent v-bind="props" @action="onAction" />
    </template>
  </AtroExpandableContent>
</template>

<script setup lang="ts">
import type { Props as ListItemContentProps } from '@/components/flow/atom/FlowAtomListItemContent.vue'
import type { Props as OverflowMenuProps } from '@/components/OverflowMenu.vue'
import type { Props as RiskProfileProps } from '@/components/risk/RiskProfileChart.vue'

export interface Props extends ListItemContentProps {
  title: string
  type: 'select' | 'status' | 'text'

  badge?: FlowAtomBadge
  bg?: 'gray' | 'white'
  color?: 'light' | 'dark'
  defaultChecked?: boolean
  expanded?: boolean
  icon?: IconName
  inline?: boolean
  modelValue?: boolean
  mutuallyExclusive?: boolean
  options?: SelectOption[]
  optionsPlaceholder?: string
  overflowMenu?: OverflowMenuProps
  riskProfile?: RiskProfileProps
  selectedOption?: string
  show?: boolean | string
  status?: 'complete' | 'error' | 'in_progress'
  subTitle?: string
  subtitle?: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
}>()
const modelValue = defineModel<Props['modelValue']>({ default: undefined })
const selectedOption = defineModel<any>('selectedOption')

const { color = 'dark' } = props
const _expanded = ref(props.expanded)

const expandable = computed(
  () => hasSubitems.value || !!props.itemSubtext || !!props.itemComponentName,
)
const hasSubitems = computed(() => (props.subitems?.length || 0) > 0)
const isClickable = computed(
  () => expandable.value || !!props.itemAction || props.type === 'select',
)
const isSelectable = computed(() => props.type === 'select')
const showRightContent = computed(
  () =>
    expandable.value ||
    props.badge ||
    props.itemActionLabel ||
    props.overflowMenu ||
    props.options ||
    props.riskProfile,
)

const itemStyleClasses = computed(() => {
  switch (props.status) {
    case 'error':
      return 'text-atro-red'
    default:
      return 'text-atro-gray-2'
  }
})

const itemIconName = computed<IconName>(() => {
  if (props.type === 'status') {
    switch (props.status) {
      case 'complete':
        return 'circle-check'
      case 'in_progress':
        return 'circle-rotate'
      case 'error':
        return 'circle-exclamation'
      default:
        return 'circle-info'
    }
  } else {
    return props.icon as IconName
  }
})

const iconClasses = computed(() => {
  switch (props.type) {
    case 'select':
      return 'h-6 w-6'
    case 'status':
      return [
        'h-6 w-6',
        {
          'text-atro-green': props.status === 'complete',
          'text-atro-red': props.status === 'error',
          'text-atro-blue': props.status === 'in_progress',
        },
      ]
    case 'text':
      return 'h-4 w-4'
    default:
      return ''
  }
})

const model = computed({
  get() {
    if (
      (props.type === 'select' &&
        typeof modelValue.value === 'undefined' &&
        props.defaultChecked) ||
      selectedOption.value
    ) {
      return true
    } else {
      return modelValue.value
    }
  },
  set(value) {
    modelValue.value = value
  },
})

onMounted(async () => {
  if (typeof modelValue.value === 'undefined' && props.defaultChecked) {
    // we have to awkwardly wait a little but for the two-way bindings to be setup
    // before we push up the change model change
    await delay(50)
    model.value = true
  }
})

function onAction(action: FlowAction) {
  emit('action', action)
}

// This can come from a click or spacebar when focused on the list item
function onActivateItem(toggleExpand: () => void) {
  if (expandable.value) toggleExpand()
  else if (props.type === 'select') {
    if (props.options) {
      document.querySelector('#optionsSelect')?.focus()
    } else if (props.mutuallyExclusive) {
      model.value = true
    } else {
      model.value = !model.value
    }
  } else if (props.itemAction) onAction(props.itemAction)
}
</script>
