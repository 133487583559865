import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()

  if (import.meta.client) {
    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: import.meta.env.VITE_SENTRY_DSN as string,
      enabled: import.meta.env.VITE_ENVIRONMENT !== 'development',
      environment: (import.meta.env.VITE_SENTRY_ENV as string) || 'preview',
      release: runtimeConfig.public.GIT_RELEASE_SHA,
      integrations: [
        Sentry.browserTracingIntegration({
          router: useRouter(),
        }),
      ],
      tracesSampleRate: 0.5,
    })
  }

  return {
    provide: {
      sentry: Sentry,
    },
  }
})
