import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/vercel/path0/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_ghbUAjaD3n from "/vercel/path0/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import nuxt_plugin_WPDdiystzB from "/vercel/path0/node_modules/vue-recaptcha/dist/nuxt-plugin.mjs";
import apex_charts_client_nU3LNFyvBu from "/vercel/path0/plugins/apex-charts.client.ts";
import auto_animate_uTpJuTGsMC from "/vercel/path0/plugins/auto-animate.ts";
import auto_expand_client_XgXhwPISRQ from "/vercel/path0/plugins/auto-expand.client.ts";
import chatwoot_crLW9YLG1U from "/vercel/path0/plugins/chatwoot.ts";
import electron_device_rFvu8uW7qu from "/vercel/path0/plugins/electron-device.ts";
import floating_vue_BiOD74u9sH from "/vercel/path0/plugins/floating-vue.ts";
import mobile_OriQq9jmDY from "/vercel/path0/plugins/mobile.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/plugins/sentry.ts";
import sonner_KD25tq4EBi from "/vercel/path0/plugins/sonner.ts";
import trackers_FpmZMuzECg from "/vercel/path0/plugins/trackers.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  plugin_ghbUAjaD3n,
  formkitPlugin_pZqjah0RUG,
  nuxt_plugin_WPDdiystzB,
  apex_charts_client_nU3LNFyvBu,
  auto_animate_uTpJuTGsMC,
  auto_expand_client_XgXhwPISRQ,
  chatwoot_crLW9YLG1U,
  electron_device_rFvu8uW7qu,
  floating_vue_BiOD74u9sH,
  mobile_OriQq9jmDY,
  sentry_3AyO8nEfhE,
  sonner_KD25tq4EBi,
  trackers_FpmZMuzECg
]