export default function useLayoutConfig() {
  const router = useRouter()
  const config = computed(
    () => router.currentRoute.value.meta.layoutConfig || ({} as any),
  )

  return {
    layoutConfig: config,
    layoutView: computed<LayoutView>(() => config.value.view),
  }
}
