import type { FormKitNode } from '@formkit/core'
import {
  AtroButton,
  AtroParagraph as Paragraph,
  AtroSpan as Span,
} from '@atro/components'
import Container from '@/components/flow/atom/FlowAtomContainer.vue'
import Grid from '@/components/formkit/Grid.vue'
import FormkitFormHeading from '@/components/formkit/FormHeading.vue'
import Markdown from '@/components/flow/atom/FlowAtomMarkdown.vue'
import Repeated from '@/components/formkit/Repeated.vue'
import Upload from '@/components/formkit/Upload.vue'

function joinError(prefix: string, message: string) {
  return `${capitalize(prefix)} ${message}`
}

export const formkitLibrary = markRaw({
  Paragraph,
  Span,
  button: AtroButton,
  container: Container,
  grid: Grid,
  Heading: FormkitFormHeading,
  heading: FormkitFormHeading,
  markdown: Markdown,
  paragaph: Paragraph,
  repeated: Repeated,
  span: Span,
  upload: Upload,
})

export function handleFormError(
  e: any,
  node?: FormKitNode | null,
  errorCodeOverrides?: Record<number, any>,
) {
  const error = e as ClientApiError

  if (errorCodeOverrides?.[error.statusCode as number]) {
    let override = ''
    if (typeof errorCodeOverrides[error.statusCode as number] === 'function') {
      override = errorCodeOverrides[error.statusCode as number](
        error.data?.errors,
      )
    } else {
      override = errorCodeOverrides[error.statusCode as number]
    }
    node?.setErrors([override])
  } else if (error.data?.errors && node) {
    const errorsNotInForm = []
    const sanatizedFieldErrors = {}

    for (const [key, value] of Object.entries(error.data.errors)) {
      if (typeof (node.value as Record<string, unknown>)[key] === 'undefined') {
        if (isObject(value)) {
          for (const [nestedKey, nestedValue] of Object.entries(value)) {
            errorsNotInForm.push(joinError(nestedKey, nestedValue))
          }
        } else {
          errorsNotInForm.push(
            Array.isArray(value)
              ? joinError(key, value[0])
              : joinError(key, value),
          )
        }
      } else {
        if (Array.isArray(value)) {
          // @ts-ignore
          sanatizedFieldErrors[key] = joinError(key, value[0])
        } else {
          // @ts-ignore
          sanatizedFieldErrors[key] = joinError(key, value)
        }
      }
    }
    node?.setErrors(errorsNotInForm, sanatizedFieldErrors)
  }
}
