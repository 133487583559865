<template>
  <AtroModal size="lg">
    <!-- HEADING -->
    <AtroContent class="mb-6 w-full" direction="col" items="center">
      <UserDisplay
        size="lg"
        :profile-modal-enabled="false"
        :truncated="false"
        :user="userShallow"
      />
      <AtroSpan
        v-if="userDetailed"
        semibold
        class="text-atro-gray"
        size="xxs"
        :text="joinedAtText"
      />
    </AtroContent>

    <AtroAsync :is-loading :fetch-request="userDetailedFetchRequest">
      <AtroContent
        class="w-full space-x-0 space-y-4 sm:max-h-[212px] sm:space-x-4 sm:space-y-0"
        :direction="$isMobile.value ? 'col' : 'row'"
      >
        <!-- MEMBER SCORE -->
        <ScoreCard
          :score="userDetailed.score"
          title="Member Score"
          type="darkCard"
        />

        <!-- NOTES -->
        <AtroContent class="h-full max-h-52 flex-1 self-stretch sm:max-h-full">
          <NoteCard
            class="h-full"
            v-model="userDetailed.notes"
            @save="onSave"
          />
        </AtroContent>
      </AtroContent>

      <!-- FLOW REPORTS -->
      <AtroSection class="mt-10" title="Assigned Security Measures">
        <UserOrgFlowReportTable
          :org-flows="orgFlowsFetchRequest?.data.value?.orgFlows"
          :user-flow-reports="userDetailed.reports"
        />
      </AtroSection>

      <!-- PHISHING HISTORY -->
      <AtroSection
        v-if="userDetailed.phishingHistory?.length"
        class="mt-10"
        title="Phishing History"
      >
        <AtroCard class="space-y-2 !bg-atro-warm-gray-2" size="full">
          <AtroContent
            v-for="phishingHistoryReport in userDetailed.phishingHistory"
            :key="phishingHistoryReport.date"
            class="w-full"
            justify="between"
          >
            <AtroContent class="space-x-2">
              <AtroIcon
                :class="[
                  'size-4',
                  getIconClassColor(phishingHistoryReport.iconColor),
                ]"
                :name="phishingHistoryReport.icon"
              />
              <AtroSpan
                class="text-atro-dark-purple"
                size="sm"
                :text="phishingHistoryReport.label"
              />
            </AtroContent>
            <AtroSpan
              class="text-atro-gray"
              size="sm"
              :text="formatDate(phishingHistoryReport.date)"
            />
          </AtroContent>
        </AtroCard>
      </AtroSection>
    </AtroAsync>
  </AtroModal>
</template>

<script setup lang="ts">
export interface Props {
  user: User
}

const { user: userShallow } = defineProps<Props>()

const orgFlowsFetchRequest = useData<OrgFlowsResponse>('orgFlows')
const userDetailedFetchRequest = useData<User>('orgUser', {
  userId: userShallow.id,
})
const { mutate } = useMutation('updateUser')
const { wrapWithPending } = usePendingWrap()

const joinedAtText = computed(() =>
  userDetailed.value.joined
    ? `Joined ${joinedAt.value}`
    : `Invited ${joinedAt.value}`,
)
const userDetailed = computed(
  () => userDetailedFetchRequest?.data.value as User,
)
const isLoading = computed(
  () =>
    orgFlowsFetchRequest?.isLoading.value ||
    userDetailedFetchRequest?.isLoading.value,
)
const joinedAt = computed(() =>
  formatDate(userDetailed.value.confirmedAt || userDetailed.value.updatedAt),
)
const onSave = wrapWithPending(async () => {
  await mutate(userDetailed.value)
})

function getIconClassColor(iconColor: string) {
  switch (iconColor) {
    case 'green':
      return 'text-atro-green'
    case 'red':
      return 'text-atro-red'
    default:
      return 'text-atro-dark-purple'
  }
}
</script>
