<template>
  <AtroContent
    :class="[
      'relative h-12 w-full px-4 py-2',
      { 'z-50': globalState.upgradeModalShown },
      { 'z-max': !globalState.upgradeModalShown },
    ]"
  >
    <slot />
  </AtroContent>
</template>

<script setup lang="ts">
const globalState = useGlobalState()

onMounted(() => {
  globalState.value.appBannerShown = true
})

onUnmounted(() => {
  globalState.value.appBannerShown = false
})
</script>
