<template>
  <AtroContent
    class="mb-6 w-full space-y-4 last:mb-0 sm:mb-12"
    direction="col"
    wrap="nowrap"
  >
    <AtroSpan
      v-if="title"
      :class="{
        'text-white': color === 'light',
        'text-atro-slate-purple': color === 'dark',
      }"
      :text="title"
    />
    <AtroContent class="w-full space-y-2" direction="col" wrap="nowrap">
      <FlowAtomListItem
        v-for="item in _items"
        v-bind="item"
        :color
        :key="item.key"
        :inline="type === 'inline'"
        :mutually-exclusive="mutuallyExclusive"
        @action="onAction"
        @update:model-value="(value: any) => setModel(item, value)"
        @update:selected-option="(value: any) => setSelectedOption(item, value)"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
import dot from 'dot-object'
import { toCamelCase } from 'js-convert-case'

export interface Props {
  items: FlowAtomListItem[]

  color?: 'light' | 'dark'
  modelValue?: Record<string, boolean> | string
  mutuallyExclusive?: boolean
  selectionRequired?: boolean
  title?: string
  type?: 'inline' | 'divided'
}

const props = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction]
  validityChange: [valid: boolean]
}>()
const modelValue = defineModel<Props['modelValue']>({ default: {} })
const {
  mutuallyExclusive,
  selectionRequired,
  color = 'dark',
  type = 'divided',
} = props

const _items = computed(() => {
  return props.items
    ?.map((item, i) => {
      const itemModelValue = item.modelValue as unknown as string
      const _modelValue =
        typeof modelValue.value === 'string'
          ? modelValue.value === itemModelValue
          : modelValue.value?.[toCamelCase(itemModelValue)]
      const selectedOption = item.options?.find(
        (option) => option.value === modelValue.value,
      )
      if (
        (typeof item.show === 'string' && !dot.pick(item.show, props)) ||
        (typeof item.show === 'boolean' && !item.show)
      ) {
        return null
      }
      return {
        ...item,
        // here we map the actual value of the model from the string reference
        ...(item.modelValue
          ? {
              key: itemModelValue,
              modelValue: _modelValue,
            }
          : {
              key: item.title,
              ...(item.options
                ? {
                    selectedOption: selectedOption?.value,
                    modelValue: !!selectedOption,
                  }
                : {}),
            }),
      }
    })
    .filter(Boolean)
})

const selectItems = computed(() =>
  _items.value?.filter((item) => item.type === 'select'),
)

watchEffect(() => {
  if (selectionRequired && selectItems.value) {
    emit(
      'validityChange',
      selectItems.value.some((selectItem) => {
        return selectItem.modelValue === true
      }),
    )
  }
})

function onAction(action: FlowAction) {
  emit('action', action)
}

function setModel(item: any, value: any) {
  if (item.options) return
  if (mutuallyExclusive) {
    modelValue.value = item.key
  } else if (typeof modelValue.value !== 'string') {
    modelValue.value = {
      ...modelValue.value,
      [item.key]: value,
    }
  }
}

function setSelectedOption(item: any, value: any) {
  if (value) modelValue.value = value
}
</script>
