<template>
  <AtroGridTableRow>
    <!-- MEMBER NAME -->
    <AtroGridTableCell>
      <UserDisplay
        class="w-full"
        default-slot-direction="row"
        size="sm"
        :user="userPhishingReport.user"
      >
        <AtroBadge
          v-if="!userPhishingReport.user.joined"
          label="Invited"
          size="sm"
        />
      </UserDisplay>
    </AtroGridTableCell>

    <!-- CURRENT STATUS -->
    <AtroGridTableCell justify="center" ref="statusEl">
      <AtroBadge class="w-full" :label="statusText" :type="statusBadgeType" />
    </AtroGridTableCell>

    <!-- EMAILS RECEIVED COUNT -->
    <AtroGridTableCell justify="center">
      <AtroBadge :label="String(userPhishingReport.emailsReceivedCount || 0)" />
    </AtroGridTableCell>

    <!-- PHISHED COUNT -->
    <AtroGridTableCell justify="center">
      <AtroBadge :label="phishedCount.toString()" :type="phishedBadgeType">
        <template v-if="phishedCount > 1" #prefix>
          <AtroIcon class="mr-1 size-3" name="triangle-exclamation" />
        </template>
      </AtroBadge>
    </AtroGridTableCell>

    <!-- CREDENTIALS INPUT COUNT -->
    <AtroGridTableCell justify="center">
      <AtroBadge
        :label="credentialsInputCount?.toString()"
        :type="credentialsInputBadgeType"
      />
    </AtroGridTableCell>

    <!-- LAST TRAINED AT -->
    <AtroGridTableCell justify="center">
      <AtroSpan
        semibold
        :class="{
          'text-atro-gray': lastTrainedAt !== '—',
          'text-atro-warm-gray': lastTrainedAt === '—',
        }"
        size="xs"
        :text="lastTrainedAt"
      />
    </AtroGridTableCell>

    <AtroGridTableActionCell class="bg-white">
      <AtroButton
        size="sm"
        text="Send Test Now"
        type="flat"
        @click="$emit('sendTest', userPhishingReport.user.id)"
      />
    </AtroGridTableActionCell>
  </AtroGridTableRow>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import type { BadgeProps } from '@atro/components'

export interface Props {
  userPhishingReport: UserPhishingReport
}

const { userPhishingReport } = defineProps<Props>()
const emit = defineEmits<{
  sendTest: [userId: string]
}>()

const statusEl = ref()
const isStatusHovered = useElementHover(statusEl)

const lastTrainedAt = computed(() => {
  if (userPhishingReport.lastTrainedAt) {
    const date = new Date(userPhishingReport.lastTrainedAt)
    return format(date, 'MM.dd.yyyy')
  } else {
    return '—'
  }
})

const credentialsInputCount = computed(() =>
  Number(userPhishingReport.credentialsInputCount || 0),
)
const credentialsInputBadgeType = computed<BadgeProps['type']>(() =>
  credentialsInputCount.value > 0 ? 'incorrect' : 'correct',
)

const phishedCount = computed(() =>
  Number(userPhishingReport.phishedCount || 0),
)
const phishedBadgeType = computed<BadgeProps['type']>(() =>
  !userPhishingReport.phishedCount || phishedCount.value === 0
    ? 'correct'
    : 'incorrect',
)

const statusText = computed(() => {
  if (isStatusHovered.value && userPhishingReport.status !== 'queued') {
    if (userPhishingReport.phishedAt) {
      const date = new Date(userPhishingReport.queuedAt)
      return `Next: ${format(date, 'MM.dd.yy')}`
    } else {
      return '—'
    }
  }

  switch (userPhishingReport.status) {
    case 'phished':
      if (userPhishingReport.phishedAt) {
        const date = new Date(userPhishingReport.phishedAt)
        return `Phished: ${format(date, 'MM.dd.yy')}`
      } else {
        return '—'
      }
    case 'queued':
      if (userPhishingReport.queuedAt) {
        const date = new Date(userPhishingReport.queuedAt)
        return `Next: ${format(date, 'MM.dd.yy')}`
      } else {
        return '—'
      }
    case 'sent':
      if (userPhishingReport.sentAt) {
        const date = new Date(userPhishingReport.sentAt)
        return `Sent: ${format(date, 'MM.dd.yy')}`
      } else {
        return '—'
      }
  }
  return '—'
})
const statusBadgeType = computed<BadgeProps['type']>(() => {
  if (isStatusHovered.value) {
    return 'neutral'
  }

  switch (userPhishingReport.status) {
    case 'phished':
      return 'incorrect'
    case 'queued':
      return 'neutral'
    case 'sent':
      return 'primary'
  }
})
</script>
