<template>
  <AtroContent class="relative w-full" direction="col" wrap="nowrap">
    <AtroContent
      v-auto-animate
      class="w-full space-y-6"
      direction="col"
      wrap="nowrap"
    >
      <QuizQuestion
        v-for="(quizQuestion, index) in model"
        :index
        :can-remove="model?.length > 1"
        :key="index"
        v-model="model[index]"
        @remove="onRemoveQuestion"
        @update:model-value="(question) => onQuestionUpdated(question, index)"
        @validity-change="
          (validity) => onQuestionValidityChange(validity, index)
        "
      />
    </AtroContent>
    <AtroContent class="mt-10 w-full" items="center" justify="center">
      <AtroButton
        icon-right="plus"
        type="fill"
        text="Add A Question"
        @click="addQuestion"
      />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  modelValue: QuizQuestion[]
}

defineProps<Props>()
const model = defineModel<Props['modelValue']>({ default: [{}] })
const emit = defineEmits<{
  validityChange: [valid: boolean]
}>()

const validity = ref<Record<number, boolean>>({})
const questions = ref<Record<number, QuizQuestion>>({})

function addQuestion() {
  model.value = [...model.value, {}]
}

function onRemoveQuestion(index: number) {
  model.value = model.value.filter((m, i) => i !== index)
  delete validity.value[index]
}

function onQuestionUpdated(question: QuizQuestion, index: number) {
  questions.value[index] = question
}

function onQuestionValidityChange(valid: boolean, index: number) {
  validity.value[index] = valid
}

watchDeep(
  [validity, questions],
  () => {
    const isValid =
      Object.values(validity.value).every((v) => v) ||
      Object.values(questions.value).every(
        (question) => !question.correctAnswer && !question.question,
      )
    emit('validityChange', isValid)
  },
  { immediate: true },
)
</script>
