<template>
  <UserListItem
    :user
    :hide-state-badge-on-hover="!!actionLabel"
    :show-state-badge="!badge"
    :show-email="!postfix"
  >
    <template #postfix>
      <AtroContent class="relative" items="center">
        <AtroButton
          v-if="actionLabel"
          class="!absolute right-0 z-1 opacity-0 group-hover:opacity-100 group-hover:delay-100"
          size="sm"
          type="flat"
          :icon-right="actionIcon"
          @click="emit('action')"
          >{{ actionLabel }}</AtroButton
        >
        <AtroContent
          v-if="postfix"
          items="center"
          :class="[
            'relative ml-2 space-x-2 sm:!ml-6',
            {
              'pointer-events-none transition-opacity group-hover:opacity-0 group-hover:delay-100':
                actionLabel,
            },
          ]"
        >
          <AtroIcon class="h-4 w-4" :name="postfix.icon" />
          <AtroSpan class="text-atro-gray-1" size="sm">{{
            postfix.text
          }}</AtroSpan>
        </AtroContent>
        <FlowAtomBadge
          v-if="badge"
          v-bind="badge"
          :class="[
            {
              'transition-opacity group-hover:opacity-0 group-hover:delay-100':
                actionLabel,
            },
          ]"
        />
      </AtroContent>
    </template>
  </UserListItem>
</template>

<script setup lang="ts">
export interface Props {
  user: User

  actionLabel?: string
  actionIcon?: IconName
  badge?: FlowAtomBadge
  postfix?: {
    icon: IconName
    text: string
  }
}

const { actionLabel } = defineProps<Props>()
const emit = defineEmits<{
  action: []
}>()
</script>
