<template>
  <AtroContent class="w-full">
    <AtroContent class="w-full" wrap="nowrap">
      <Form
        v-model="formData"
        :classes="{
          form: 'flex space-x-4 ',
        }"
        @submit="onSubmit"
      >
        <FormKit
          type="text"
          name="name"
          label="Name"
          placeholder="Company name"
          validation="required"
          maxlength="50"
          :classes="{
            input: '!max-w-full',
            outer: 'w-full',
            messages: 'absolute',
          }"
        />
        <FormKit
          type="email"
          name="email"
          label="Contact's email"
          placeholder="Contact's email"
          validation="required|email"
          maxlength="32"
          :classes="{
            input: '!max-w-full',
            outer: 'w-full',
            messages: 'absolute',
          }"
        />

        <template #submit="{ state: { valid } }">
          <AtroButton :pending :disabled="!valid">
            <AtroIcon class="h-6 w-6" name="plus" />
          </AtroButton>
        </template>
      </Form>
    </AtroContent>

    <!-- COMPANY LIST -->
    <AtroContent class="mt-10 w-full" direction="col">
      <AtroContent class="w-full" justify="between">
        <AtroSpan class="text-atro-gray-1" text="Assigned" />
        <AtroButton
          icon-right="trash"
          text="Unassign all"
          type="flat"
          :disabled="!model?.length"
          @click="unassignAll"
        />
      </AtroContent>

      <AtroContent class="mt-2 w-full space-y-2" v-auto-animate>
        <AtroContent
          v-for="(business, i) in model"
          class="group w-full rounded-2xl bg-atro-warm-gray-2 px-4 py-3"
          items="center"
          justify="between"
          :key="business.email"
        >
          <AtroContent class="space-x-2">
            <AtroSpan class="text-atro-dark-purple" :text="business.name" />
            <AtroSpan class="text-atro-gray-1" :text="business.email" />
          </AtroContent>
          <AtroButton
            class="ml-6 opacity-0 transition-opacity group-hover:opacity-100"
            type="flat"
            @click="unassignBusiness(i)"
          >
            <AtroIcon class="h-4 w-4 text-primary" name="trash" />
          </AtroButton>
        </AtroContent>
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
type Business = {
  name: string
  email: string
}

export interface Props {
  modelValue?: Business[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
  'update:modelValue': [model: Props['modelValue']]
}>()
const model = useVModel(props, 'modelValue', emit, {
  defaultValue: [],
})

const formData = ref<Business>()

const { onSubmit, pending } = useFormSubmit<typeof formData.value>(
  async (data, node) => {
    data && model.value?.push(data)
    node?.reset()
  },
)

function unassignBusiness(index: number) {
  model.value?.splice(index, 1)
}

function unassignAll() {
  model.value?.splice(0, model.value.length)
}
</script>
