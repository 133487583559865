<template>
  <AtroContent
    items="center"
    wrap="nowrap"
    :class="['w-full  space-x-4', { 'group cursor-pointer': hasClick }]"
    :tabbable="hasClick"
  >
    <div
      :class="[
        'relative h-6 w-6 shrink-0 rounded-full border-2 after:absolute after:bottom-full after:left-1/2 after:h-[44px] after:w-0.5 after:-translate-x-1/2',
        {
          'after:hidden': hideBar,
          'border-none bg-atro-green p-1 text-white': taskCompleted,
          'after:bg-atro-green': previousTaskCompleted,
          'after:bg-atro-warm-gray': !previousTaskCompleted,
        },
      ]"
    >
      <Transition name="fade">
        <AtroIcon v-if="taskCompleted" name="check" />
      </Transition>
    </div>
    <AtroContent
      :class="[
        'w-full space-x-2 rounded-lg border bg-atro-warm-gray-2 p-3 transition-all',
        {
          'opacity-50': taskCompleted,
          'group-hover:border-primary group-hover:text-primary group-focus-visible:border-primary group-focus-visible:ring-1 group-focus-visible:ring-offset-3 group-active:opacity-75':
            hasClick,
        },
      ]"
      shrink
      wrap="nowrap"
      items="center"
    >
      <AtroIcon class="h-8 w-8 text-atro-gray-1" :name="task.icon" />
      <AtroContent class="w-full text-left" shrink direction="col">
        <AtroHeading class="text-atro-gray-2" size="xs" :text="task.title" />
      </AtroContent>
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  task: OnboardingTask

  clickable?: boolean
  hideBar?: boolean
  previousTaskCompleted?: boolean
}

const props = defineProps<Props>()
const { clickable = true } = props

const { completedTaskSlugs } = useOnboarding()

const hasClick = computed(() => clickable && !taskCompleted.value)
const taskCompleted = computed(() =>
  completedTaskSlugs.value.includes(props.task.slug),
)
</script>
