<template>
  <MainContentCard :flat="pageType === 'modal'">
    <!-- Main Column -->
    <MainContent
      :class="{
        'after:h-10 after:w-full after:shrink-0': pageType === 'modal',
        'h-full': page.centered,
      }"
      items="center"
      :justify="page.centered ? 'center' : 'start'"
      :key="page.slug"
      :width="props.page.fullWidth ? 'full' : 'md'"
    >
      <slot name="mainPrefix" />

      <!-- MAIN COMPONENTS -->
      <FlowComponent
        v-for="config in mainComponents"
        :config
        :data
        :deps
        :key="config.name"
        @action="onAction"
        @refresh="emit('refreshData')"
        @scroll-to="onScrollTo"
        @submit="(data) => emit('submit', data)"
        @validity-change="(valid: boolean) => onValidityChange(valid)"
      />
    </MainContent>

    <!-- Aside Column -->
    <template v-if="hasAsideComponents">
      <AsideContent v-if="!$isMobile.value" class="justify-between">
        <slot name="asidePrefix" />

        <AtroContent
          v-auto-animate
          class="w-full space-y-4"
          direction="col"
          wrap="nowrap"
        >
          <FlowComponent
            v-for="config in asideComponents"
            :config
            :data
            :deps
            :key="config.name"
            @action="onAction"
            @refresh="emit('refreshData')"
            @validity-change="(valid: boolean) => onValidityChange(valid)"
          />
        </AtroContent>
      </AsideContent>

      <ModalMobileFlowAside
        v-else
        :data
        :deps
        :components="asideComponents"
        :is-open="mobileAsideModalOpen"
        @action="onAction"
        @refresh="emit('refreshData')"
        @close="mobileAsideModalOpen = false"
      />
    </template>
  </MainContentCard>
</template>

<script setup lang="ts">
export interface Props {
  data: Record<string, any>
  deps: Record<string, any>
  page: Page
  pageType: 'main' | 'modal'

  width?: 'md' | 'lg' | 'full'
}

const props = defineProps<Props>()
const emit = defineEmits<{
  action: [page: Page, action: FlowAction]
  refreshData: []
  submit: [data: any]
}>()

const { mobileAsideModalOpen, pageFocus, scrollingEl, valid } = usePageState()
const { y: scrollY } = useScroll(scrollingEl, { behavior: 'smooth' })

const dataPoll = ref()

const asideComponents = computed(() => props.page?.asideComponents || [])
const mainComponents = computed(() => props.page?.mainComponents || [])
const hasAsideComponents = computed(() => !!asideComponents.value.length)
const isDataPending = computed(() => {
  return !!mainComponents.value.find(
    (componentConfig) =>
      componentConfig.type === 'status_card' &&
      componentConfig.props.status === 'pending',
  )
})

function onAction(action: FlowAction) {
  emit('action', props.page, action)
}

function onScrollTo({ y = 0 }: { x?: number; y?: number }) {
  const currentScroll = scrollingEl.value?.scrollTop || 0
  scrollY.value = y + currentScroll - 420
}

function onValidityChange(validity: boolean) {
  if (pageFocus.value === props.pageType) valid.value = validity
}

watch(
  isDataPending,
  (updated) => {
    clearInterval(dataPoll.value)
    if (updated && import.meta.client) {
      dataPoll.value = setInterval(() => emit('refreshData'), 1000)
    }
  },
  { immediate: true },
)
</script>
