type UseFetchOptions<T> = Parameters<typeof useFetch<T>>[1]
type Options<T> = UseFetchOptions<T> & { pendingUntilRefresh?: boolean }

export default function useData<T>(
  pathname: keyof typeof apiPaths | null | false,
  params: Record<string, unknown> | null = {},
  options: Options<T> = {},
) {
  if (!pathname) return null
  const headers = useRequestHeaders(['cookie']) as HeadersInit
  const { org } = useCurrentOrg()
  const { user } = useCurrentUser()

  // @ts-ignore don't know a clean way to map the dynamic possible amount
  // of props here to the fixed amount in the api def
  const url = apiPaths[pathname]({
    ...(org.value && { orgId: org.value.id }),
    ...(user.value && { userId: user.value.id }),
    ...params,
  })
  if (url === null) return null

  // data is loaded on initial page load in server context so we can initialze
  // hasFetched based on if we're in the server context or not
  const key = options.key || url
  const hasFetched = useState(key, () => !!import.meta.server)
  const request = useFetch<T>(url, {
    headers,
    key,
    ...options,
  }) as FetchRequest<T>
  request.hasFetched = hasFetched

  request.isEmpty = computed(() => {
    if (request.status.value === 'success' && hasFetched.value) {
      if (Array.isArray(request.data.value) && request.data.value.length === 0)
        return true
      if (
        isObject(request.data.value) &&
        Object.keys(request.data.value as Record<string, unknown>).length === 0
      ) {
        return true
      }
      return !request.data.value
    }
    return false
  })

  request.isLoading = computed(
    () =>
      request.status.value === 'pending' &&
      !(hasFetched.value && !options.pendingUntilRefresh),
  )

  // watch only occurs in the cliet so here we can handle client side loading states
  watchEffect(() => {
    if (!hasFetched.value && request.status.value !== 'pending') {
      hasFetched.value = true
    }
  })

  return request
}
