<template>
  <AtroModal size="sm">
    <AtroModalHeader text="Change Email" />

    <AtroSpan class="mt-2 text-atro-gray-1"
      >Currently: {{ user.email }}</AtroSpan
    >

    <Form v-model="formData" @submit="onSubmit">
      <FormKit
        name="email"
        type="email"
        label="New email"
        validation="required|email"
      />

      <template #submit="{ state: { valid } }">
        <AtroButton :pending text="Update Email" :disabled="!valid" />
      </template>
    </Form>
  </AtroModal>
</template>
<script setup lang="ts">
export interface ModalChangeEmailProps {
  user: User
}

const { user } = defineProps<ModalChangeEmailProps>()

// const { updateUserEmail, isLoading: isPending } = useUserEmailUpdateMutation()

const formData = ref({
  email: '',
})

const { onSubmit, pending } = useFormSubmit<typeof formData.value>(async () => {
  console.log('email change submit')
})
</script>
