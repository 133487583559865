<template>
  <AtroModal
    no-overlay
    no-padding
    class="overflow-hidden !shadow-purple"
    size="md"
    :closeable="false"
  >
    <template #default="{ close }">
      <OnboardingStateContent welcome @skip-onboarding="() => onSkip(close)" />
    </template>
  </AtroModal>
</template>
<script setup lang="ts">
export interface ModalOnboardingWelcomeProps {}

defineProps<ModalOnboardingWelcomeProps>()

const { seenOnboarding } = useOnboarding()
onMounted(seenOnboarding)

function onSkip(close: () => void) {
  close()
  navigateToWithOrg('')
}
</script>
