<template>
  <HeadlessCombobox v-model="modelValue" class="w-full" multiple nullable>
    <div class="relative">
      <AtroContent class="relative" wrap="nowrap" items="center">
        <HeadlessComboboxInput
          :placeholder
          :class="[
            'rounded-xl border-2 !border-solid border-atro-warm-gray !pl-10',
            formkitTheme.global.input,
            formkitTheme['family:text'].input,
            { 'rounded-r-none': $slots.menu },
          ]"
          :display-value="(user: any) => user?.displayName || user?.email"
          @change="query = $event.target.value"
          @keyup.enter="onEnter"
        />
        <HeadlessComboboxButton
          class="absolute inset-y-0 left-0 flex items-center pl-4"
        >
          <AtroIcon class="h-4 w-4 text-atro-gray-1" name="magnifying-glass" />
        </HeadlessComboboxButton>

        <AtroContent v-if="$slots.menu" class="flex items-center self-stretch">
          <slot name="menu" />
        </AtroContent>
      </AtroContent>

      <HeadlessTransitionRoot
        leave="transition ease-in duration-100"
        leave-from="opacity-100"
        leave-to="opacity-0"
        @after-leave="query = ''"
      >
        <HeadlessComboboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-2xl border-2 bg-white pl-0 text-base focus:outline-none"
        >
          <AtroContent
            v-if="filteredUsers.length === 0 && query !== ''"
            class="relative w-full cursor-default select-none px-4 py-2.5 text-atro-gray"
            items="center"
            justify="between"
          >
            <AtroSpan text="No results found" />
            <AtroButton
              v-if="queryIsEmails"
              icon-right="plus"
              icon-size="md"
              text="Invite to Atro"
              type="flat"
              @click="onInvite"
            />
          </AtroContent>

          <HeadlessComboboxOption
            v-for="user in filteredUsers"
            v-slot="{ selected, active }"
            as="template"
            :key="user.id"
            :value="user.id"
          >
            <li
              :class="[
                'relative flex cursor-pointer select-none items-center justify-between px-4 py-2.5',
                {
                  'bg-primary text-white': active,
                  'text-primary': !active,
                },
              ]"
            >
              <AtroSpan
                :class="['block truncate']"
                :semibold="selected"
                :text="user.displayName || user.email"
              />
              <AtroBadge v-if="selected" label="Assigned" size="sm" />
              <AtroSpan v-else-if="active" semibold size="xs" text="Assign" />
            </li>
          </HeadlessComboboxOption>
        </HeadlessComboboxOptions>
      </HeadlessTransitionRoot>
    </div>
  </HeadlessCombobox>
</template>

<script setup lang="ts">
import { theme as formkitTheme } from '@atro/formkit-config'

export interface Props {
  modelValue: string[]
  users: User[]

  placeholder?: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  inviteUsers: [email: string]
  'update:modelValue': [model: Props['modelValue']]
}>()
const modelValue = useVModel(props, 'modelValue', emit)
const { users, placeholder = 'Enter an email to assign or invite a member' } =
  props

const query = ref('')

const queryIsEmails = computed(() => isEmails(query.value))

const filteredUsers = computed(() => {
  if (!users) return []
  return query.value === ''
    ? users
    : users.filter((user) => {
        const hasDisplayName = user.displayName
          ?.toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value?.toLowerCase().replace(/\s+/g, ''))
        if (hasDisplayName) return true

        return user.email
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value?.toLowerCase().replace(/\s+/g, ''))
      })
})

function onInvite() {
  emit('inviteUsers', query.value)
}

function onEnter() {
  queryIsEmails.value && onInvite()
}
</script>
