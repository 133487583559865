export const changeOwner = async ({
  body,
  params,
}: {
  body: { userId: string }
  params: { orgId: string }
}) => {
  const path = getApiPath('changeOwner', params)

  return updateResource(path, { body: { userId: body.userId } })
}

export const createRecommendedFlows = async ({
  params,
}: {
  params: { orgId: string }
}) => {
  const path = getApiPath('createRecommendedFlows', params)

  return createResource(path, {
    key: path,
  })
}

export const deleteOrg = async ({ params }: { params: { orgId: string } }) => {
  const path = getApiPath('org', params)

  return deleteResource(path, {
    id: params.orgId,
    key: path,
  })
}

export const updateOrg = async ({
  body,
  params,
}: {
  body: Org
  params: Parameters<typeof apiPaths.org>[0]
}) => {
  const path = getApiPath('org', params)

  return updateResource<Org>(path, {
    body,
    key: path,
  })
}
