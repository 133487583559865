<template>
  <AtroContent
    shrink
    :class="[
      'group hover:text-primary',
      { 'cursor-pointer': profileModalEnabled },
    ]"
    items="center"
    wrap="nowrap"
    @click="
      (e) => {
        if (profileModalEnabled) {
          e.stopPropagation()
          openModal('userProfile', { props: { user } })
        }
      }
    "
  >
    <UserAvatar
      v-if="!hideAvatar"
      :user
      :size="size === 'sm' && $isMobile.value ? 'md' : size"
    />
    <AtroContent
      shrink
      wrap="nowrap"
      :class="[
        classFn({ size }),
        {
          'space-x-2': isRow,
          'space-y-1': !isRow && ['xl', 'lg'].includes(size),
        },
      ]"
      :direction="isRow ? 'row' : 'col'"
      :items="isRow ? 'center' : 'start'"
    >
      <AtroContent
        shrink
        wrap="nowrap"
        :class="[{ 'space-x-2': size === 'sm' && !$isMobile.value }]"
        :direction="size === 'sm' && !$isMobile.value ? 'row' : 'col'"
      >
        <AtroSpan
          :class="['w-full sm:w-auto', { truncate: truncated }]"
          :semibold="size !== 'sm'"
          :size="size"
          :text="name"
        />

        <AtroSpan
          v-if="email && !$isMobile.value"
          size="md"
          :class="[
            'w-full text-atro-gray-1 sm:w-auto',
            {
              truncate: truncated,
              'opacity-0 transition-opacity group-hover:opacity-100':
                showEmailOnHover,
            },
          ]"
          :text="email"
        />
      </AtroContent>

      <AtroBadge v-if="showInvitedBadge" label="Invited" size="sm" />
      <slot />
    </AtroContent>
  </AtroContent>
</template>

<script lang="ts">
import { classed } from '@tw-classed/core'
import type { Props as UserAvatarProps } from '@/components/user/UserAvatar.vue'

const classFn = classed('w-full flex-1', {
  variants: {
    size: {
      sm: 'ml-2',
      md: 'ml-2',
      lg: 'ml-3',
      xl: 'ml-6',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})
</script>

<script setup lang="ts">
export interface Props {
  user: User
  profileModalEnabled?: boolean
  hideAvatarWhenMobile?: boolean
  isRow?: boolean
  showEmail?: boolean
  showEmailOnHover?: boolean
  showInvitedBadge?: boolean
  size?: UserAvatarProps['size']
  truncated?: boolean
}

const {
  showEmail,
  showEmailOnHover,
  user,
  hideAvatarWhenMobile = false,
  isRow = true,
  profileModalEnabled = true,
  size = 'md',
  truncated = true,
} = defineProps<Props>()

const isMobile = useIsMobile()
const { openModal } = useModal()

const hideAvatar = computed(() => isMobile.value && hideAvatarWhenMobile)
const name = computed(() => user.displayName || user.email)
const email = computed(() =>
  (showEmail || showEmailOnHover) && user.displayName ? user.email : '',
)
</script>
