export function useVercelPreview() {
  const runtimeConfig = useRuntimeConfig()

  const isPreview = computed(
    () =>
      runtimeConfig.public.VERCEL_ENV === 'preview' &&
      runtimeConfig.public.VERCEL_BRANCH_URL !==
        'atro-nuxt-git-develop-atro.vercel.app',
  )
  const previewBranchName = computed(
    () => runtimeConfig.public.VERCEL_BRANCH_NAME,
  )
  const previewBranchUrl = computed(
    () => `https://${runtimeConfig.public.VERCEL_BRANCH_URL}`,
  )

  return { isPreview, previewBranchName, previewBranchUrl }
}
