<template>
  <AtroGridTableRow>
    <!-- TOOL NAME -->
    <AtroGridTableCell>
      <NuxtLink :to="flowPath">
        <AtroContent class="space-x-2 hover:text-primary" items="center">
          <AtroIcon class="size-5" :name="flow.icon" />
          <AtroSpan size="sm" :text="flow.title" />
        </AtroContent>
      </NuxtLink>
    </AtroGridTableCell>

    <!-- ALERTS -->
    <AtroGridTableCell>
      <AtroBadge
        size="sm"
        :label="alertCount?.toString()"
        :type="alertCount ? 'incorrect' : 'neutral'"
      />
    </AtroGridTableCell>

    <!-- SCORE -->
    <ScoreGridTableCell size="sm" :score="userFlowReport.score" />

    <!-- STATUS -->
    <TeamReportTableUserFlowReportCell :flow :user-flow-report />
  </AtroGridTableRow>
</template>
<script setup lang="ts">
export interface Props {
  flow: OrgFlow
  userFlowReport: UserFlowReport
}

const { flow, userFlowReport } = defineProps<Props>()

const { flowPath } = useFlowMeta(flow)

const alertCount = computed(() => getUserFlowReportAlertCount(userFlowReport))
</script>
