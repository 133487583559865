<template>
  <AtroModal
    actions-divided
    fullscreen-when-mobile
    no-padding
    :size="hasAside ? 'xl' : 'lg'"
  >
    <template v-if="page">
      <FlowPageController
        :data
        :deps
        :page
        class="text-left"
        page-type="modal"
        @action="onAction"
      />
    </template>

    <template v-if="page?.action" #actions>
      <AtroContent class="w-full" justify="center">
        <AtroButton
          :pending
          class="!px-18"
          :disabled="!valid"
          :text="page?.actionLabel"
          @click="onModalAction"
        />
      </AtroContent>
    </template>
  </AtroModal>
</template>
<script setup lang="ts">
export interface ModalPageProps {
  data: Record<string, any>
  deps: {
    flow: Flow
    users: User[]
  }

  page?: Page
}

const { page } = defineProps<ModalPageProps>()
const emit = defineEmits<{
  action: [page: Page, action: FlowAction]
  modalEvent: [type: string, payload: any]
}>()

const { pageFocus, pending, valid, reset: resetPageState } = usePageState()
const { closeModal } = useModal()

const hasAside = computed(() => (page?.asideComponents || []).length > 0)

function onModalAction() {
  if (page) onAction(page, { type: page.action })
  closeModal()
}

function onAction(actionPage: Page, action: FlowAction) {
  if (page) emit('modalEvent', 'action', [actionPage, action])
}

onMounted(() => {
  resetPageState()
  pageFocus.value = 'modal'
})
onUnmounted(resetPageState)
</script>
