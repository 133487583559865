<template>
  <AtroContent class="w-full space-x-2" items="center">
    <AtroDot :class="statusColor" />
    <AtroSpan :text size="sm" />
    <FlowAtomInfoTooltip v-if="tooltip" :tooltip />
  </AtroContent>
</template>

<script setup lang="ts">
export interface Props {
  status: 'error' | 'ok' | 'warn'
  text: string
  tooltip?: FlowTooltip
}

const props = defineProps<Props>()

const statusColor = computed(() => {
  switch (props.status) {
    case 'error':
      return 'bg-atro-red'
    case 'ok':
      return 'bg-atro-green'
    case 'warn':
      return 'bg-atro-yellow'
  }
})
</script>
