<template>
  <AtroContent class="w-full flex-1" direction="col">
    <AtroContent
      class="mb-6 w-full flex-1"
      direction="col"
      justify="center"
      items="center"
    >
      <template v-if="!invitesSent">
        <AtroHeading
          semibold
          class="text-atro-slate-purple"
          text="Invite your team"
        />
        <AtroParagraph class="mt-4 text-center text-atro-gray-1"
          >Add team members to Atro via email invite or sharing the
          link</AtroParagraph
        >
        <OrgShareInviteLinkButton class="mt-4" />
      </template>

      <template v-else>
        <AtroIcon class="h-18 w-18" name="email-sent" />
        <AtroHeading
          semibold
          class="mt-2 text-atro-slate-purple"
          text="Invite sent!"
        />
        <AtroSpan
          class="mt-2 text-atro-gray-1"
          text="Invited members will receive an invite soon"
        />
      </template>
    </AtroContent>

    <Form
      v-if="!invitesSent"
      v-model="formData"
      input-width="full"
      @submit="onSubmit"
    >
      <FormKit
        type="textarea"
        name="emails"
        label="Emails"
        placeholder="Emails, comma separated"
        :classes="{
          outer: 'w-full',
        }"
        :validation="[
          ['required'],
          [
            'matches',
            /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,\s*)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,
          ],
          ['length:5,9999'],
        ]"
        maxlength="9999"
      />
      <FormKit
        type="select"
        name="role"
        label="Permissions"
        placeholder="Select Permissions"
        validation="required"
        :classes="{
          outer: 'w-full',
        }"
        :options="ROLE_OPTIONS"
      />

      <template #submit="{ state: { valid } }">
        <AtroButton
          :pending
          class="mx-auto"
          :disabled="!valid"
          :text="inviteCtaText"
        />
      </template>
    </Form>

    <AtroContent v-else class="w-full" direction="col">
      <AtroButton
        block
        icon-right="chevron-right"
        text="Invite More"
        type="transparent"
        @click="invitesSent = false"
      />
      <slot name="afterSendActions" />
    </AtroContent>
  </AtroContent>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  invitesSent: []
}>()

const formData = ref({
  emails: '',
  role: null,
})
const invitesSent = ref(false)

const { mutate } = useMutation('sendInvites')

const { onSubmit, pending } = useFormSubmit<{ emails: string; role: Role }>(
  async (data, form) => {
    const { emails, role } = data

    // split on comma or space
    const emailArray = emails.split(/[ ,]+/)
    await mutate({ role, emails: emailArray })
    invitesSent.value = true
    form?.reset()
    emit('invitesSent')
    refreshNuxtData()
  },
)

const inviteCtaText = computed(() => {
  return formData.value.emails.split(/[ ,]+/).length === 0
    ? 'Send Invite'
    : 'Send Invites'
})
</script>
