import { toCamelCase } from 'js-convert-case'
import OnboardingOrgDataInput from '@/components/onboarding/OnboardingOrgDataInput.vue'
import OnboardingStepDataInput from '@/components/onboarding/OnboardingStepDataInput.vue'
import OnboardingUserDataInput from '@/components/onboarding/OnboardingUserDataInput.vue'

const userDataInputStep: OnboardingStepConfig = {
  component: OnboardingUserDataInput,
  type: 'userDataInput',
}

const orgDataInputStep: OnboardingStepConfig = {
  component: OnboardingOrgDataInput,
  type: 'orgDataInput',
}

export default function useOnboarding() {
  const globalState = useGlobalState()
  const router = useRouter()
  const { analytics } = useAnalytics()
  const { status, data: session } = useAuth()
  const { org, isKioskMode } = useCurrentOrg()
  const { user } = useCurrentUser()
  const { isMember } = useCurrentRole()
  const { missingOrg } = useUserOrgs()
  const { saveUiState, setUiState, uiContent, uiOrgState } = useUiConfig()

  const isAuthenticated = computed(() => status.value === 'authenticated')
  const isCreatingAnotherOrg = computed(
    () => router.currentRoute.value.path === '/create-org',
  )

  // ONBOARDING
  const completedTaskSlugs = computed(
    () => onboardingState.value?.completedTasks || [],
  )
  const completedOnboardingTasks = computed(() =>
    onboardingTasks.value?.filter((task) =>
      completedTaskSlugs.value.includes(task.slug),
    ),
  )
  const currentOnboardingTask = computed(
    () => remainingOnboardingTasks.value[0],
  )
  const hasRemainingOnboardingTasks = computed(
    () => (remainingOnboardingTasks.value?.length || 0) > 0,
  )
  const hasSeenFlowAssigned = computed(
    () => onboardingState.value?.seenFlowAssigned === true,
  )
  const hasSeenOnboardingModal = computed(
    () => onboardingState.value?.seenOnboarding === true,
  )
  const isOnboardingComplete = computed(
    () => !hasRemainingOnboardingTasks.value,
  )
  const lastCompletedTask = computed(
    () =>
      completedOnboardingTasks.value?.[
        completedOnboardingTasks.value.length - 1
      ],
  )
  const onboardingContent = computed(() => uiContent.value?.onboarding)
  const onboardingState = computed(() => uiOrgState.value?.onboarding)
  const onboardingTasks = computed(() => onboardingContent.value?.tasks || [])
  const remainingOnboardingTasks = computed(() =>
    onboardingTasks.value?.filter(
      (task) => !completedTaskSlugs.value.includes(task.slug),
    ),
  )
  const showOnboardingWidget = computed(
    () =>
      isOnboarding.value &&
      router.currentRoute.value.name?.toString().includes('orgs-slug') &&
      router.currentRoute.value.name?.toString() !== 'orgs-slug-welcome',
  )

  function getTaskFromIdentifier(type: OnboardingTaskType, id: string) {
    return onboardingTasks.value.find(
      (task) => task.action.type === type && task.action.data === id,
    )
  }

  function isTaskCompleted(task: OnboardingTask) {
    return completedTaskSlugs.value.includes(task?.slug)
  }

  function jumpToTask(task: OnboardingTask) {
    if (!isTaskCompleted(task)) {
      navigateToWithOrg(`/flows/${task.action.data}/welcome`)
      analytics.value?.track('onboarding:task:started', { task: task.slug })
    }
  }

  function nextTask(task?: OnboardingTask) {
    const _task = task || currentOnboardingTask.value
    jumpToTask(_task)
  }

  async function openWidget(
    options: { delay?: number; confetti?: boolean } = {},
  ) {
    await wait(options.delay || 0)
    globalState.value.showOnboardingWidget = true
    if (options.confetti) {
      globalState.value.onboardingWidgetConfetti = true
      await wait(5000)
      globalState.value.onboardingWidgetConfetti = false
    }
  }

  function skipTask(task: OnboardingTask) {
    const taskIndex = remainingOnboardingTasks.value.indexOf(task)
    const nextTaskToView =
      remainingOnboardingTasks.value[taskIndex + 1] ||
      remainingOnboardingTasks.value[0]

    nextTask(nextTaskToView)
    analytics.value?.track('onboarding:task:skipped')
  }

  // ONBOARDING V2
  const onboardingContentV2 = computed(() => uiContent.value?.onboardingV2)
  const onboardingStateV2 = computed(() => uiOrgState.value?.onboardingV2)
  const hasCompletedOnboardingSteps = computed(
    () => onboardingStateV2.value?.hasCompletedOnboardingSteps,
  )
  const hasSeenLoading = computed(() => onboardingStateV2.value?.loadingSeen)
  const isOnboarding = computed(
    () =>
      missingOrg.value ||
      (isMember.value && shouldCollectUserData.value) ||
      (!isMember.value &&
        org.value &&
        !isKioskMode.value &&
        !hasCompletedOnboardingSteps.value &&
        !isWalkthroughSkipped.value),
  )
  const isUserMissingRequiredData = computed(
    () => !user.value?.firstName || !user.value?.lastName,
  )
  const shouldConnectUser = computed(
    () => isAuthenticated.value && session.value?.claimToken,
  )
  const shouldCollectUserData = computed(
    () =>
      isAuthenticated.value &&
      (isUserMissingRequiredData.value || missingOrg.value),
  )

  // data collection step controller
  const initialDataCollectionSteps = ref([])
  if (isUserMissingRequiredData.value) {
    initialDataCollectionSteps.value.push(userDataInputStep)
  }
  if (missingOrg.value || isCreatingAnotherOrg.value) {
    initialDataCollectionSteps.value.push(orgDataInputStep)
  }

  const onboardingSteps = computed<OnboardingStepConfig[]>(() => {
    // modify data collection steps into OnboardingStepConfigs
    return [
      ...initialDataCollectionSteps.value,
      ...(isMember.value
        ? []
        : onboardingContentV2.value?.dataCollectionSteps.map((step, i) => {
            return {
              component: OnboardingStepDataInput,
              type: 'onboaringStep',
              props: {
                step,
                modelValue: [],
              },
            } as OnboardingStepConfig
          }) || []),
    ]
  })
  const stepIndex = ref(
    isCreatingAnotherOrg.value
      ? 0
      : Object.keys(onboardingStateV2.value?.completedSteps || {}).length,
  )
  const stepsCount = computed(() => onboardingSteps.value.length)
  const currentStep = computed(() => onboardingSteps.value[stepIndex.value])
  const isFinalStep = computed(
    () => onboardingSteps.value.length - 1 === stepIndex.value,
  )
  function nextStep() {
    if (stepIndex.value + 1 < onboardingSteps.value.length) {
      stepIndex.value += 1
    }
  }
  // WALKTHROUGH
  const currentWalkthroughStep = computed(() =>
    walkthroughSteps.value.find(
      (step) => !walkthroughStepsSeen.value.includes(step.key),
    ),
  )
  const currentWalkthroughStepIndex = computed(() =>
    walkthroughSteps.value.findIndex(
      (step) => step.key === currentWalkthroughStep.value?.key,
    ),
  )
  const isLastWalkthroughStep = computed(
    () =>
      currentWalkthroughStepIndex.value === walkthroughSteps.value.length - 1,
  )
  const isWalkthroughActive = computed(
    () => walkthroughStatus.value === 'active',
  )
  const isWalkthroughSkipped = computed(
    () => walkthroughStatus.value === 'skipped',
  )
  const walkthroughStatus = computed(
    () => onboardingStateV2.value?.walkthroughStatus,
  )
  const walkthroughSteps = computed(
    () => onboardingContentV2.value?.walkthroughSteps || [],
  )
  const walkthroughStepsSeen = computed(
    () => onboardingStateV2.value?.walkthroughStepsSeen || [],
  )

  function nextWalkthroughStep() {
    if (isLastWalkthroughStep.value) {
      setOnboardingV2State('walkthroughStatus', 'completed')
    }
    setOnboardingV2State('walkthroughStepsSeen', [
      ...walkthroughStepsSeen.value,
      currentWalkthroughStep.value?.key,
    ])
  }

  function skipWalkthrough() {
    setOnboardingV2State('walkthroughStatus', 'skipped')
  }

  // MUTATIONS
  function completeOnboarding() {
    setOnboardingState('status', 'completed')
    analytics.value?.track('onboarding:completed')
  }
  function markOnboardingTaskComplete(task: OnboardingTask) {
    if (!onboardingState.value?.completedTasks.includes(task.slug)) {
      onboardingState.value?.completedTasks.push(task.slug)
      saveUiState('org')
      openWidget({ confetti: true, delay: 3500 })
      analytics.value?.track('onboarding:task:completed', { task: task.slug })
    }
  }
  function seenOnboarding() {
    setOnboardingState('seenOnboarding', true)
    analytics.value?.track('onboarding:seen')
  }
  function skipOnboarding() {
    setOnboardingState('status', 'skipped')
    analytics.value?.track('onboarding:skipped')
  }
  function setOnboardingState(key: keyof OnboardingState, value: any) {
    return setUiState('org', `onboarding.${key}`, value)
  }
  function setOnboardingV2State(key: string, value: any) {
    return setUiState('org', `onboardingV2.${key}`, value)
  }

  return {
    // ONBOARDING V1
    completedOnboardingTasks,
    completedTaskSlugs,
    currentOnboardingTask,
    hasRemainingOnboardingTasks,
    hasSeenFlowAssigned,
    hasSeenOnboardingModal,
    isOnboardingComplete,
    lastCompletedTask,
    onboardingTasks,
    remainingOnboardingTasks,
    showOnboardingWidget,
    completeOnboarding,
    getTaskFromIdentifier,
    isTaskCompleted,
    jumpToTask,
    markOnboardingTaskComplete,
    nextTask,
    openWidget,
    seenOnboarding,
    setOnboardingState,
    skipTask,
    skipOnboarding,

    // ONBOARDING V2
    currentStep,
    hasCompletedOnboardingSteps,
    hasSeenLoading,
    isCreatingAnotherOrg,
    isFinalStep,
    isOnboarding,
    nextStep,
    onboardingStateV2,
    onboardingSteps,
    setOnboardingV2State,
    shouldConnectUser,
    stepsCount,
    stepIndex,

    // WALKTHROUGH
    currentWalkthroughStep,
    currentWalkthroughStepIndex,
    isWalkthroughActive,
    isWalkthroughSkipped,
    nextWalkthroughStep,
    skipWalkthrough,
    walkthroughStatus,
    walkthroughSteps,
  }
}
